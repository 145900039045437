import { Box, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

function SoraTextField({ label, labelSx, value, onChange, rows, tabIndex }) {
	return (
		<Box sx={{ width: '100%' }}>
			{label && (
				<Typography
					variant="subtitle2"
					sx={{ marginBottom: 1, ...labelSx }}
				>
					{label}
				</Typography>
			)}
			<TextField
				fullWidth
				multiline
				value={value}
				onChange={(e) => onChange(e.target.value)}
				rows={rows}
				tabIndex={tabIndex}
			/>
		</Box>
	);
}

SoraTextField.propTypes = {
	label: PropTypes.string,
	labelSx: CommonPropTypes.sx,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	rows: PropTypes.number
};

SoraTextField.defaultProps = {
	label: 'Additional Notes',
	labelSx: {},
	rows: 8
};

export default SoraTextField;
