import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import {
	Box,
	IconButton,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogContentText,
	Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

function Modal({
	children,
	isOpen,
	handleClose,
	title,
	TitleComponent,
	subtitle,
	sx
}) {
	const SoraTheme = useTheme();

	// If this doesn't cover all options lets pass an optional Prop.
	const overflow = 'auto';

	return (
		<Dialog
			component="div"
			open={isOpen}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			sx={{
				margin: '3rem 2rem 2rem 2rem',
				'& .MuiPaper-rounded ': {
					borderRadius: '40px',
					overflow,
					...sx
				}
			}}
		>
			<IconButton
				aria-label="close"
				fontSize="64px"
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: '0',
					top: '0',
					marginTop: 2,
					marginRight: 2,
					marginBottom: 2
				}}
			>
				<CloseIcon />
			</IconButton>
			<Box
				sx={{
					paddingTop: 6,
					paddingLeft: 4,
					paddingRight: 4,
					paddingBottom: 6
				}}
			>
				{title && (
					<DialogTitle>
						<Typography
							variant="h2Gascogne"
							sx={{ color: SoraTheme.palette.primary.indigo }}
						>
							{title}
						</Typography>
					</DialogTitle>
				)}
				{!title && TitleComponent}

				<DialogContent>
					{subtitle && (
						<DialogContentText component="div">
							<Typography
								variant="body1"
								sx={{
									marginBottom: 4,
									lineHeight: '1.5rem'
								}}
							>
								{subtitle}
							</Typography>
						</DialogContentText>
					)}

					{children}
				</DialogContent>
			</Box>
		</Dialog>
	);
}

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	sx: CommonPropTypes.sx
};

Modal.defaultProps = {
	title: '',
	subtitle: '',
	sx: {}
};

export default Modal;
