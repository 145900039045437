import { Grid, Typography } from '@mui/material';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import RateInput from 'components/Input/RateInput';

function SimpleAssetsSection({
	sx,
	title,
	presentValue,
	setPresentValue,
	annualGrowth,
	setAnnualGrowth
}) {
	return (
		<>
			<Typography sx={sx} variant="body1">
				<strong>{title}</strong>
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={4}>
					<CurrencyTextInput
						label="Present value"
						value={presentValue}
						onChange={setPresentValue}
						variant="body2"
					/>
				</Grid>
				<Grid item xs={4}>
					<RateInput
						subLabel=""
						label="Annual growth"
						rate={annualGrowth}
						setRate={setAnnualGrowth}
						variant="body2"
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default SimpleAssetsSection;
