import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import AnalyticsClientList from 'components/Analytics/AnalyticsClientList';

// Hooks
import useSmartAnalyticsTabNavigation from 'hooks/analytics/smartTabNavigation/useSmartAnalyticsNavigation';

function AnalyticsIntroCard({
	disabled,
	background,
	headingImage,
	headingText,
	descriptionText,
	textColor,
	tabIndex
}) {
	const [clientSearchName, setClientSearchName] = useState('');

	const smartNavigate = useSmartAnalyticsTabNavigation();

	const onSelect = (clientSelected) => {
		smartNavigate({ tabIndex, clientSelected }, false);
	};

	return (
		<Grid item xs={3}>
			<Box
				sx={{
					background,
					borderRadius: 4,
					height: 450,
					padding: 4,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Box
					component="img"
					src={headingImage}
					sx={{ width: 56, height: 56 }}
				/>

				<Typography variant="h2Gascogne" sx={{ color: textColor }}>
					{headingText}
				</Typography>

				<Box sx={{ height: 43 }}>
					<Typography variant="body1" sx={{ color: textColor }}>
						{descriptionText}
					</Typography>
				</Box>

				<Box sx={{ width: '100%' }}>
					<AnalyticsClientList
						disabled={disabled}
						handleClientSelect={onSelect}
						clientSearchName={clientSearchName}
						handleClientSearchName={setClientSearchName}
					/>
				</Box>
			</Box>
		</Grid>
	);
}

AnalyticsIntroCard.propTypes = {
	disabled: PropTypes.bool,
	background: PropTypes.string.isRequired,
	descriptionText: PropTypes.string.isRequired,
	headingImage: PropTypes.string.isRequired,
	headingText: PropTypes.string.isRequired,
	textColor: PropTypes.string.isRequired
};

AnalyticsIntroCard.defaultProps = {
	disabled: false
};

export default AnalyticsIntroCard;
