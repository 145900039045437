/* eslint-disable consistent-return */
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from 'react-query';
import { Box, Grid, Typography } from '@mui/material';
import { isURL, isMobilePhone } from 'validator';

// Our Components
import AvatarUpload from 'components/FileUpload/AvatarUpload';
import Checkbox from 'components/Checkbox/Checkbox';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import Modal from 'components/Modal/Modal';
import { PrimaryButton } from 'components/Button/Button';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';

// Our Query keys
import {
	FA_COMPANY_URL,
	FA_COMPANY_ASSOCIATION,
	FA_ASSOCIATION_DATA,
	TEMP_CROPPED_COMPANY_LOGO_DATA
} from 'shared/query-keys';

// Our routes
import { FA_ONBOARDING_THANKS_ROUTE } from 'routes';

// Our hooks
import useMutateCheckDomain from 'hooks/useMutateCheckDomain';
import useMutateAddProfileData from 'hooks/useMutateAddProfileData';
import useMutateUploadCompanyLogo from 'hooks/advisorProfile/useMutateUploadCompanyLogo';

// Our Utils
import { isSubmissionReady } from 'shared/utils';

// Our Constants
import { TEXT } from 'components/Input/Types';
import PhoneNumberWhitelist from 'shared/phone-number-whitelist.json';

export function FaAdditionalDetailsPage() {
	const queryClient = useQueryClient();
	const updateProfileData = useMutateAddProfileData();
	const checkForCompanyDomain = useMutateCheckDomain();
	const uploadCompanyLogo = useMutateUploadCompanyLogo();

	const { isLoading: updateProfileDataLoading } = updateProfileData;
	const { isLoading: uploadCompanyLogoLoading } = uploadCompanyLogo;

	// Defining useNavigate from react router dom
	const navigate = useNavigate();

	// Profile State
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

	// Company State
	const [companyName, setCompany] = useState('');
	const [companyWebsite, setCompanyWebsite] = useState('');
	const [numberOfClients, setNumberOfClients] = useState('');

	const isValidWebsite = useMemo(
		() => isURL(companyWebsite),
		[companyWebsite]
	);
	const isValidPhoneNumber = useMemo(() => {
		if (PhoneNumberWhitelist.includes(phoneNumber)) {
			return true;
		}
		return isMobilePhone(phoneNumber, 'en-US');
	}, [phoneNumber]);

	const isCompanyNotWebsite = useMemo(
		() => !isURL(companyName),
		[companyName]
	);

	const isFormReady = useMemo(
		() =>
			isSubmissionReady([
				firstName,
				lastName,
				phoneNumber,
				companyName,
				companyWebsite
			]) &&
			isValidWebsite &&
			isCompanyNotWebsite &&
			isValidPhoneNumber,
		[firstName, lastName, phoneNumber, companyName, companyWebsite]
	);

	const getCompanyWebsiteErrorMessage = useMemo(() => {
		if (companyWebsite === '') return 'Company website cannot be empty';
		if (!isValidWebsite) return 'Company website is not valid';
	}, [companyWebsite]);

	const getCompanyNameErrorMessage = useMemo(() => {
		if (companyName === '') return 'Company name cannot be empty';
		if (!isCompanyNotWebsite) return 'Company name cannot be a website';
	}, [companyName]);

	// Company association modal state
	const [isAssociatedToCompany, setCompanyAssociation] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isCompanyFound, setIsCompanyFound] = useState(null);
	const fullModalTitle = `Are you associated to the company ${modalTitle}?`;

	const showUploadCompanyLogo = useMemo(() => {
		const isCompanyFoundWasUpdated = isCompanyFound !== null;
		const isBrandNewCompany = !isCompanyFound;

		if (
			isCompanyFoundWasUpdated &&
			isBrandNewCompany &&
			companyWebsite !== '' &&
			isValidWebsite
		)
			return true;
		return false;
	}, [isCompanyFound, companyWebsite]);

	// Submit company URL to backend when user loses focus of companyURL input
	const checkForCompany = () => {
		// This if fired when the companyURL loses focus,
		// I've got this additional check in place incase the user leaves the field blank
		const companyWebsiteIsNotBlank = companyWebsite !== '';

		if (companyWebsiteIsNotBlank) {
			// Setting the companyURL entered by user to react query
			queryClient.setQueryData(FA_COMPANY_URL, companyWebsite);

			// call mutation to check if our backend finds an associated company
			checkForCompanyDomain.mutate(
				{ companyWebsite },
				{
					// Will always come back successful
					onSuccess: (companyData) => {
						// Store the response from mutation
						// ie. the company association data response from the mutation
						// call to our API
						queryClient.setQueryData(
							FA_COMPANY_ASSOCIATION,
							companyData
						);

						// Parsing response to check for no company association
						const companyFoundFlag =
							companyData !== 'Company Domain Not Found';

						// Setting global boolean based on if company is found or not
						setIsCompanyFound(companyFoundFlag);

						if (companyFoundFlag) {
							// Pull the company name out of the data and set the modal title after data is received
							setModalTitle(companyData.companyName);
						}
					}
				}
			);
		}
	};

	// Handle field change
	const handleFirstNameChange = (value) => {
		setFirstName(value);
	};

	const handleLastNameChange = (value) => {
		setLastName(value);
	};

	const handleCompanyChange = (value) => {
		setCompany(value);
	};

	const handleCompanyWebsiteChange = (value) => {
		setCompanyWebsite(value);
	};

	// Handle Additional Details form submit,
	const submitUserInformation = () => {
		// Fetch company association data for user, stored in the checkForCompany()
		const userCompanyAssociationData = queryClient.getQueryData(
			FA_COMPANY_ASSOCIATION
		);

		// If companyFound is true, get the id of that company otherwise set company id to 0
		const companyId = isCompanyFound ? userCompanyAssociationData.id : 0;

		// Setting up payload for the 3 possible outcomes of this flow.

		// 1) No company association found
		let userData = {
			firstName,
			lastName,
			phoneNumber,
			companyName,
			companyWebsite
		};

		// 2) Company association found and confirmed
		// including companyId in payload for backend to make connection
		if (isCompanyFound && isAssociatedToCompany) {
			userData = { ...userData, companyId };
		}

		// 3) Company association found and denied
		// including only the firstName, lastName and phoneNumber because the company association identified with the backend
		// has been denied, therefore we don't want to save this association data in our DB.
		if (isCompanyFound && !isAssociatedToCompany) {
			userData = { firstName, lastName, phoneNumber };
		}

		// Creating flags to store in react query
		const associationData = {
			// eslint-disable-next-line object-shorthand
			isCompanyFound: isCompanyFound,
			// eslint-disable-next-line object-shorthand
			isAssociatedToCompany: isAssociatedToCompany
		};
		// Storing company association flags in react query for use during later
		queryClient.setQueryData(FA_ASSOCIATION_DATA, associationData);

		// Triggering this mutation for all 3 scenarios
		updateProfileData.mutateAsync(userData, {
			onSuccess: async () => {
				const companyLogoToUpload = queryClient.getQueryData(
					TEMP_CROPPED_COMPANY_LOGO_DATA
				);

				await uploadCompanyLogo.mutateAsync(companyLogoToUpload, {
					onSettled: () => {
						// clean up the cached image
						queryClient.removeQueries(
							TEMP_CROPPED_COMPANY_LOGO_DATA
						);
						navigate(FA_ONBOARDING_THANKS_ROUTE);
					}
				});
			}
		});
	};

	const triggerModalOpen = (e) => {
		e.preventDefault();
		if (isCompanyFound) {
			// Open our modal asking the user to confirm the company association
			setIsOpen(true);
		} else {
			submitUserInformation();
		}
	};

	return (
		<>
			<Helmet>
				<title>User information</title>
			</Helmet>

			<Grid item xs={12} sx={{ marginTop: 4, marginBottom: 4 }}>
				<Typography variant="h1Gascogne" component="h1" gutterBottom>
					Please provide your contact information
				</Typography>
			</Grid>

			<Grid
				item
				container
				xs={12}
				columnSpacing={4}
				sx={{ paddingRight: 2 }}
			>
				<Grid item xs={6}>
					{/* First Name */}
					<Input
						id="firstName"
						label="First Name"
						type={TEXT}
						onChange={handleFirstNameChange}
						value={firstName}
						error={firstName === ''}
						helperText="First name cannot be empty"
						inputProps={{
							tabIndex: 1
						}}
					/>

					{/* Company */}
					<Input
						id="company"
						label="Company"
						type={TEXT}
						value={companyName}
						onChange={handleCompanyChange}
						placeholder="Company name"
						error={companyName === '' || !isCompanyNotWebsite}
						helperText={getCompanyNameErrorMessage}
						inputProps={{
							tabIndex: 3
						}}
						sx={{ marginTop: 2 }}
					/>

					{/* Phone Number */}
					<PhoneNumberInput
						phoneNumber={phoneNumber}
						onChange={setPhoneNumber}
						isValidPhoneNumber={isValidPhoneNumber}
						sx={{ marginTop: 2 }}
						inputProps={{
							tabIndex: 5
						}}
					/>

					<AvatarUpload withoutAnimation />
				</Grid>
				<Grid item xs={6}>
					{/* Last Name */}
					<Input
						id="lastName"
						label="Last Name"
						type={TEXT}
						onChange={handleLastNameChange}
						value={lastName}
						error={lastName === ''}
						helperText="Last name cannot be empty"
						sx={{}}
						inputProps={{
							tabIndex: 2
						}}
					/>
					{/* Company Website */}
					<Input
						id="companyWebsite"
						label="Company Website"
						type={TEXT}
						onChange={handleCompanyWebsiteChange}
						value={companyWebsite}
						onBlur={checkForCompany}
						error={!isValidWebsite}
						helperText={getCompanyWebsiteErrorMessage}
						placeholder="companywebsite.com"
						sx={{ marginTop: 2 }}
						inputProps={{
							tabIndex: 4
						}}
					/>
					{/* Number of Clients */}
					<Input
						id="numberOfClients"
						label="Total Number of clients"
						type={TEXT}
						value={numberOfClients}
						onChange={setNumberOfClients}
						subLabel="The number of clients you have"
						placeholder="# of clients"
						sx={{ marginTop: 2 }}
						inputProps={{
							tabIndex: 6
						}}
					/>

					<AvatarUpload isCorporate appear={showUploadCompanyLogo} />
				</Grid>
			</Grid>

			{(updateProfileDataLoading || uploadCompanyLogoLoading) && (
				<Loader boxSX={{ marginTop: 2 }} />
			)}
			{!updateProfileDataLoading && !uploadCompanyLogoLoading && (
				<PrimaryButton
					onClick={triggerModalOpen}
					disabled={!isFormReady}
					sx={{ marginTop: 4 }}
					// eslint-disable-next-line jsx-a11y/tabindex-no-positive
					tabIndex={7}
				>
					Next
				</PrimaryButton>
			)}

			<Modal
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				title={fullModalTitle}
			>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Box component="form" noValidate autoComplete="off">
							<Checkbox
								label="Yes"
								checked={isAssociatedToCompany}
								onChange={() => setCompanyAssociation(true)}
								sx={{
									marginTop: 2
								}}
							/>
							<br />

							<Checkbox
								label="No"
								checked={!isAssociatedToCompany}
								onChange={() => setCompanyAssociation(false)}
								sx={{
									marginTop: 4
								}}
							/>
							<br />

							{updateProfileDataLoading && <Loader />}
							{!updateProfileDataLoading && (
								<PrimaryButton
									onClick={submitUserInformation}
									sx={{
										marginTop: 2
									}}
								>
									Submit
								</PrimaryButton>
							)}
						</Box>
					</Grid>
				</Grid>
			</Modal>
		</>
	);
}

export default FaAdditionalDetailsPage;
