import { Box, Grid, Typography } from '@mui/material/index';
import {
	BarChart,
	Bar,
	Legend,
	XAxis,
	YAxis,
	Tooltip,
	ReferenceLine,
	ResponsiveContainer,
	CartesianGrid
} from 'recharts';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

// Our Components
import ResponsiveTable from 'components/Table/ResponsiveTable';
import SoraToolTip from 'components/Tooltip/index';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import createFormattedRowData from 'shared/utils/analytics/createFormattedRowData';

function NetPresentValueTab({ analyticsNPVData }) {
	const SoraTheme = useTheme();

	const { analytics } = SoraTheme.palette;

	const netPresentValueTableRows = useMemo(() => {
		if (analyticsNPVData?.currentLoan && analyticsNPVData?.bestOffer) {
			const { currentLoan, bestOffer } = analyticsNPVData;
			return [
				[
					'Remaining Tenure',
					currentLoan.remainingMonth,
					bestOffer.remainingMonth
				],
				createFormattedRowData(
					'Monthly Payment',
					currentLoan.monthlyPayment,
					bestOffer.monthlyPayment
				),
				['', '', ''],
				createFormattedRowData(
					'PV',
					currentLoan.presentValue,
					bestOffer.presentValue
				),
				['', '', ''],
				createFormattedRowData(
					'NPV',
					analyticsNPVData.netPresentValue,
					''
				)
				// createFormattedRowData(
				// 	'NPV of Monthly Payment Savings',
				// 	' ',
				// 	bestOffer.npvMonthlyPaymentSavings
				// ),
				// ['', '', ''],
				// createFormattedRowData(
				// 	'NPV of Refinance',
				// 	'',
				// 	analyticsNPVData?.netPresentValue
				// )
			];
		}

		return [];
	}, [analyticsNPVData]);

	const graphData = useMemo(() => {
		if (analyticsNPVData) {
			const { bestOffer, netPresentValue } = analyticsNPVData;

			return [
				{
					// name: 'NPV of Balance Outstanding',
					currentPV: Math.floor(
						analyticsNPVData.currentLoan.presentValue
					)
				},
				{
					// name: 'NPV of Monthly Payment Savings',
					newLiabilityPV: Math.floor(bestOffer.presentValue)
				},
				{
					// name: 'NPV of Refinancing',
					refinancingNPV: Math.floor(netPresentValue)
				}
			];
		}
		return [];
	}, [analyticsNPVData]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
				<Typography
					variant="h6"
					sx={{
						color: SoraTheme.palette.primary.soraBlue,
						marginBottom: 6
					}}
				>
					NPV of Refinancing
				</Typography>
				<SoraToolTip text="The net present value represents the present value of the future savings you will have by refinancing your mortgage to the new loan offer." />
			</Box>

			<Grid container spacing={2}>
				<Grid
					item
					xs={12}
					xl={6}
					sx={{
						marginBottom: 4,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<ResponsiveContainer width="90%" height={500}>
						<BarChart layout="vertical" data={graphData}>
							<CartesianGrid horizontal={false} />
							<Tooltip
								formatter={(value) =>
									dollarFormatter.format(value)
								}
							/>
							<Legend />
							<XAxis
								type="number"
								tickFormatter={(yAxisTickValue) => {
									const formattedValue =
										dollarFormatter.format(yAxisTickValue);
									return formattedValue;
								}}
							/>

							<YAxis
								hide="true"
								type="category"
								dataKey="name"
								width={150}
							/>

							<ReferenceLine x={0} stroke="black" />

							<Bar
								dataKey="currentPV"
								fill={analytics.netPresentValueExisting}
								minPointSize={100}
								name="PV of Existing Liablility"
								label={{
									fill: 'white',
									formatter: (currentPV) =>
										dollarFormatter.format(currentPV ?? 0)
								}}
							/>

							<Bar
								dataKey="newLiabilityPV"
								fill={analytics.netPresentValueNewLiability}
								minPointSize={100}
								name="PV of New Liablility"
								label={{
									fill: 'white',
									formatter: (newLiabilityPV) =>
										dollarFormatter.format(
											newLiabilityPV ?? 0
										)
								}}
							/>
							<Bar
								dataKey="refinancingNPV"
								fill={analytics.netPresentValueRefi}
								minPointSize={200}
								name="NPV of Refinancing"
								label={{
									fill: 'black',
									formatter: (refinancingNPV) =>
										dollarFormatter.format(
											refinancingNPV ?? 0
										)
								}}
							/>
						</BarChart>
					</ResponsiveContainer>
				</Grid>

				<Grid item xs={12} xl={6} sx={{ marginBottom: 4 }}>
					<ResponsiveTable
						makeLastBold
						columns={['', 'Current Loan', 'Best Offer']}
						rows={netPresentValueTableRows}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default NetPresentValueTab;
