import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

function SoraInsightsCarouselHeader() {
	const SoraTheme = useTheme();

	return (
		<Typography
			variant="h2Gascogne"
			sx={{ color: SoraTheme.palette.primary.indigo }}
		>
			Sora Insights
		</Typography>
	);
}

export default SoraInsightsCarouselHeader;
