import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Session } from '@talkjs/react';
import Talk from 'talkjs';

// Our Components
import Loader from 'components/Loader/index';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { TALKJS_ALL_UNREAD_MESSAGES } from 'shared/query-keys';

// Our Utils
import countUnreadMessages from 'shared/utils/talkjs/countUnreadMessages';

// Our Constants
import { ADMIN, CLIENT } from 'shared/constants';

const TALKJS_APP_ID = process.env.REACT_APP_TALKJS_APP_ID;

// Expected
function ProtectedRoute({ element }) {
	const queryClient = useQueryClient();
	const { isSuccess, isError, isLoading, data } = useGetProfile();

	const syncUser = useCallback(() => {
		if (isSuccess) {
			const { email, firstName, lastName, role, talkJsId } = data;

			if (role === CLIENT) return null;

			if (role === ADMIN) {
				const { adminTalkJsId } = data;

				return new Talk.User({
					id: adminTalkJsId,
					name: 'Sora',
					email: ['contact@sorafinance.com'],
					role: ADMIN
				});
			}

			const name = `${firstName} ${lastName}`;

			return new Talk.User({
				id: talkJsId,
				name,
				role: 'default',
				email: [email]
			});
		}

		return 'sample_user_sebastion';
	}, [data, isSuccess]);

	if (isLoading) return <Loader />;

	if (isError) return <Navigate to="/login" />;

	if (isSuccess) {
		const { role } = data;

		if (role === CLIENT) return element;

		// talkjs code here
		return (
			<Session
				appId={TALKJS_APP_ID}
				syncUser={syncUser}
				onUnreadsChange={(conversations) => {
					const unreadMessageCount =
						countUnreadMessages(conversations);

					queryClient.setQueryData(
						TALKJS_ALL_UNREAD_MESSAGES,
						unreadMessageCount
					);
				}}
			>
				{element}
			</Session>
		);
	}

	return element;
}

export default ProtectedRoute;
