import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Our components
import Loader from 'components/Loader';

// Our endpoints
import {
	userLogin as userLoginUrl,
	userOnboardingStatus as userOnboardingStatusUrl
} from 'shared/api-urls';

import { getAnalytics, setUserId } from 'firebase/analytics';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our Query keys
import {
	ERROR_MESSAGE_DATA,
	CLIENTINVITECODE,
	FIREBASEUSERCREDENTIALS,
	FROM_SIGNUP,
	ROLE,
	USER_DATA,
	USERPROFILEDATA,
	UNVERIFIED_USER
} from 'shared/query-keys';

// Routes
import {
	CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE,
	CLIENT_ONBOARDING_ASSETS_ROUTE,
	CLIENT_ONBOARDING_INCOME_ROUTE,
	CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE,
	CLIENT_REVIEW_ROUTE,
	CLIENT_SORAWALLET_ROUTE,
	DASHBOARD_ROUTE,
	FA_ONBOARDING_ADDITIONAL_DETAILS_ROUTE,
	FA_ONBOARDING_THANKS_ROUTE,
	LOGIN_ROUTE,
	SETTINGS_ROUTE
} from 'routes';

// Constants
const ADVISOR = 'ADVISOR';
const CLIENT = 'CLIENT';
const ADMIN = 'SORA_ADMIN';

// This maps the state to the route.
const ADVISOR_LOGIN_STATES = {
	UNVERIFIED: FA_ONBOARDING_THANKS_ROUTE,
	ONBOARDING_INCOMPLETE: FA_ONBOARDING_ADDITIONAL_DETAILS_ROUTE, // if user is an advisor and they ARE NOT yet fully onboarded, direct to onboarding flow
	ACTIVE: DASHBOARD_ROUTE // if user is an advisor and they ARE fully onboarded, direct to their /dashboard
};

// This maps the state the route
const CLIENT_LOGIN_STATES = {
	ASSETS_UPDATED: CLIENT_REVIEW_ROUTE, // Done with Assets
	INCOME_UPDATED: CLIENT_ONBOARDING_ASSETS_ROUTE, // Done with income
	LIABILITIES_UPDATED: CLIENT_ONBOARDING_INCOME_ROUTE, // Done with Liabilities
	LIABILITY_ACCOUNT_CONNECTED: CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE, // Done with Methodfi
	REVIEW_COMPLETED: CLIENT_SORAWALLET_ROUTE // Done with Review

	//	CREDIT_SCORE_ADDED: CLIENT_SORAWALLET_ROUTE, // Legacy
	//	SSN4_ADDED: CLIENT_SORAWALLET_ROUTE, // Legacy
	//	VETERAN_STATUS_UPDATED: CLIENT_SORAWALLET_ROUTE // Legacy
};

// Possible Client States
const ASSETS_UPDATED = 'ASSETS_UPDATED';
const INCOME_UPDATED = 'INCOME_UPDATED';
const LIABILITIES_UPDATED = 'LIABILITIES_UPDATED';
const LIABILITY_ACCOUNT_CONNECTED = 'LIABILITY_ACCOUNT_CONNECTED';
const REVIEW_COMPLETED = 'REVIEW_COMPLETED';

// legacy
// const CREDIT_SCORE_ADDED = 'CREDIT_SCORE_ADDED';
// const SSN4_ADDED = 'SSN4_ADDED';
// const VETERAN_STATUS_UPDATED = 'VETERAN_STATUS_UPDATED';

function RoutingUserAfterLogin() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const analytics = getAnalytics();

	// Retrieve access token for api call
	const fireBaseUserAccessToken = queryClient.getQueryData(
		FIREBASEUSERCREDENTIALS
	);

	// Retrieve role for api call
	const role = queryClient.getQueryData(ROLE);

	// Create capitalized version of role to pass to endpoint
	const capitalizedRole = role?.toUpperCase();

	// If user was an invitee, pull the inviteeToken out of react query
	const clientInviteToken = queryClient.getQueryData(CLIENTINVITECODE);

	const roleOrToken =
		role === CLIENT
			? { inviteCode: clientInviteToken }
			: { role: capitalizedRole };

	const {
		isLoading,
		error,
		isSuccess,
		data: userProfileData
	} = useQuery(USER_DATA, async () => {
		const loginResponse = await axiosInstance.post(userLoginUrl, {
			firebaseIdToken: fireBaseUserAccessToken,
			...roleOrToken
		});
		return loginResponse.data.data;
	});

	// useEffect is used to fix error Warning: Cannot update a component(`BrowserRouter`) while rendering a different component
	// Documentation for fix - https://github.com/facebook/react/issues/18178#issuecomment-595846312

	useEffect(() => {
		if (isSuccess) {
			queryClient.setQueryData(USERPROFILEDATA, userProfileData);

			// Getting USER_DATA from react query, in order to parse id for user
			const userData = queryClient.getQueryData(USER_DATA);

			// Passing id for user through to our firebase analytics
			setUserId(analytics, userData.id);

			// Gather and parse user's profile data, based on the contained flags
			// direct user to correct location of the website.

			// Get the role from out API instead of the role stored in react query, taken from the url

			const confirmedRole = userProfileData.role;
			const isInvalidRole = confirmedRole === undefined;
			const isAdvisor = confirmedRole === ADVISOR;
			const isClient = confirmedRole === CLIENT;
			const isAdmin = confirmedRole === ADMIN;

			// no idea what we are dealing with go back to login
			if (isInvalidRole) {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an issue reading the role of the account. Please login again.'
				);
				navigate(LOGIN_ROUTE);
				return;
			}

			// If the user is a SORA ADMIN, then direct them to the settings page after login
			if (isAdmin) {
				navigate(SETTINGS_ROUTE);
				return;
			}

			// hello advisor lets see what you have done.
			if (isAdvisor) {
				// When an Advisor is unverified we are setting this query data
				if (userProfileData?.status === 'UNVERIFIED')
					queryClient.setQueryData(
						UNVERIFIED_USER,
						userProfileData?.status
					);

				// Within the profile call we are given a status field.
				// Using that status value we pass it into our Hashmap of ADVISOR_LOGIN_STATES
				// which returns the route that it should go to based on the status
				const advisorRouteBasedOnState =
					ADVISOR_LOGIN_STATES[userProfileData?.status];

				// temporary due to https://sorafinance.atlassian.net/browse/SWB-1135

				// root cause is that our App expects login to return full profile response
				// it does not provide address details.
				// getProfile gives full response includiong address to the solution is to refetch the data.
				const resetProfileCacheAndNavigate = async () => {
					await queryClient.resetQueries({
						queryKey: USERPROFILEDATA
					});

					navigate(advisorRouteBasedOnState);
				};

				resetProfileCacheAndNavigate();
				return;
			}

			// ah yes the client. Lets have fun.
			if (isClient) {
				axiosInstance
					.get(userOnboardingStatusUrl)
					.then((res) => res.data.data)
					.then((onboardData) => {
						const filteredOnboardData = onboardData.filter(
							(onboardState) =>
								onboardState !== 'Unknown Client State'
						);

						// Brand new client accounts should have nothing in onboarding status
						if (filteredOnboardData.length < 1) {
							queryClient.setQueryData(FROM_SIGNUP, true);
							navigate(
								CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE
							);
							return;
						}

						// Create a unique set of states bc its possible to have duplicate entries for whatever reason.
						const filteredOnboardStates = new Set(
							filteredOnboardData
						);

						// if REVIEW_COMPLETED is there they finished onboarding
						if (filteredOnboardStates.has(REVIEW_COMPLETED)) {
							const route = CLIENT_LOGIN_STATES[REVIEW_COMPLETED];
							const resetProfileCacheAndNavigate = async () => {
								await queryClient.resetQueries({
									queryKey: USERPROFILEDATA
								});

								navigate(route);
							};

							resetProfileCacheAndNavigate();
							return;
						}

						// if ASSETS_UPDATED take them to review page
						if (filteredOnboardStates.has(ASSETS_UPDATED)) {
							const route = CLIENT_LOGIN_STATES[ASSETS_UPDATED];
							navigate(route);
							return;
						}

						// if INCOME_UPDATED take them to assets page
						if (filteredOnboardStates.has(INCOME_UPDATED)) {
							const route = CLIENT_LOGIN_STATES[INCOME_UPDATED];
							navigate(route);
							return;
						}

						// if LIABILITIES_UPDATED take them to income page (pinwheel)
						if (filteredOnboardStates.has(LIABILITIES_UPDATED)) {
							const route =
								CLIENT_LOGIN_STATES[LIABILITIES_UPDATED];
							navigate(route);
							return;
						}

						// if LIABILITY_ACCOUNT_CONNECTED take them to liabilities identified page aka (post methodfi)
						if (
							filteredOnboardStates.has(
								LIABILITY_ACCOUNT_CONNECTED
							)
						) {
							const route =
								CLIENT_LOGIN_STATES[
									LIABILITY_ACCOUNT_CONNECTED
								];
							navigate(route);
							return;
						}

						// If we made it here no error occurred and we have not collected firstName LastName etc.
						// take them to user-details page

						queryClient.setQueryData(FROM_SIGNUP, true);
						navigate(CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE);
					})
					.catch((e) => {
						queryClient.setQueryData(
							ERROR_MESSAGE_DATA,
							e?.message
						);
						navigate(LOGIN_ROUTE);
					});
			}
		}
	}, [userProfileData]);

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Sora Routing User</title>
				</Helmet>
				<Loader
					boxSX={{
						width: '100%',
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					size={100}
				/>
			</>
		);

	if (error) {
		queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
		navigate(LOGIN_ROUTE);
	}

	return (
		<>
			<Helmet>
				<title>Sora Routing User</title>
			</Helmet>
			<Loader
				boxSX={{
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}
				size={100}
			/>
		</>
	);
}

export default RoutingUserAfterLogin;
