import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Loader from 'components/Loader';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';
import { PrimaryButton } from 'components/Button/Button';

// Text Input types
import { NUMBER, TEXT } from 'components/Input/Types';

// Utils
import { isSubmissionReady } from 'shared/utils';
import convertDateToDatePickerFormat from 'shared/utils/formatting/convertDateToDatePickerFormat';
import getNonNullValues from 'shared/utils/clientOnboarding/getNonNullValues';

// Our routes
import { CLIENT_SORAWALLET_ROUTE } from 'routes';

// Hooks
import useGetProfile from 'hooks/useGetProfile';
import useGetAllUserLiabilities from 'hooks/clientOnboarding/useGetAllUserLiabilities';
import useGetIncome from 'hooks/Onboarding/Income/useGetIncome';
import useMutateUpdateProfileData from 'hooks/useMutateUpdateProfileData';

// Constants
import {
	ALL,
	AUTO,
	CITIZEN_STATUS_OPTIONS,
	CREDITCARD,
	EDUCATION_LEVEL_OPTIONS,
	EMPLOYMENT_STATUS_OPTIONS,
	MORTGAGE,
	STUDENT,
	PERSONAL,
	VETERAN_STATUS_OPTIONS
} from 'shared/constants';

const FIELDS_TO_IGNORE = new Set(['id', 'email', 'phoneNumber']);

// helper function select userProfile related fields
const selectOnlyRequiredFields = ({
	address,
	citizenshipStatus,
	creditScore,
	dob,
	employmentStatus,
	firstName,
	highestDegree,
	lastName,
	mfiCreditScore,
	monthlyRent,
	phoneNumber,
	veteran
}) => {
	const userProfileDataWithoutNullValues = getNonNullValues({
		address,
		citizenshipStatus,
		creditScore,
		dob,
		employmentStatus,
		firstName,
		highestDegree,
		lastName,
		mfiCreditScore,
		monthlyRent,
		phoneNumber,
		veteran
	});

	return userProfileDataWithoutNullValues;
};

function getSumOfFieldBasedOnTradeLine(
	desiredTradeLineType,
	userLiabilityData,
	fieldName
) {
	// if ALL is passed it will just sum a specific field
	if (desiredTradeLineType === ALL) {
		return userLiabilityData.reduce(
			(acc, currentLiability) => acc + (currentLiability[fieldName] ?? 0),
			0
		);
	}
	// Creates an array of desired tradeline type
	const filteredLiabilitiesBasedOnType = userLiabilityData.filter(
		({ tradeLineType }) => tradeLineType === desiredTradeLineType
	);

	// sums a specific field of that tradeline
	const totalSumOfFieldsBasedOnType = filteredLiabilitiesBasedOnType.reduce(
		(acc, currentLiability) => acc + currentLiability[fieldName],
		0
	);

	return totalSumOfFieldsBasedOnType;
}

function OnboardingReviewForm() {
	const navigate = useNavigate();
	const updateProfileData = useMutateUpdateProfileData();

	// Api Calls
	const { data, isSuccess, isLoading } = useGetProfile(
		selectOnlyRequiredFields
	);

	const {
		data: userLiabilityData,
		isLoading: userLiabilityIsLoading,
		isSuccess: userLiabilityIsSuccess
	} = useGetAllUserLiabilities();

	const {
		data: userIncomeData,
		isLoading: userIncomeIsLoading,
		isSuccess: userIncomeIsSuccess
	} = useGetIncome();

	// Required fields
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [dob, setDOB] = useState(null);
	const [citizenshipType, setCitizenshipType] = useState('US Citizen');
	const [isVeteran, setIsVeteran] = useState('');
	const [educationRating, setEducationRating] = useState('');
	const [employmentStatus, setEmploymentStatus] = useState('Employed');

	// Address fields
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	// Optional fields
	const [creditScore, setCreditScore] = useState('');
	const [monthlyRentPayment, setMonthlyRentPayment] = useState('');
	const [jobTitle, setJobTitle] = useState('');
	const [employer, setEmployer] = useState('');

	// Non editable form fields
	const [totalHomeLiabilities, setTotalHomeLiabilities] = useState('');
	const [totalStudentLiabilities, setTotalStudentLiabilities] = useState('');
	const [totalAutoLiabilities, setTotalAutoLiabilities] = useState('');
	const [totalOtherLiabilities, setTotalOtherLiabilities] = useState('');
	const [totalMonthlyDebtPayment, setTotalMonthlyDebtPayment] = useState('');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState('');

	// separate
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	useEffect(() => {
		if (isSuccess) {
			setDisableAutoComplete(true);
			const WANTED_KEY_SET_MAPPING = {
				addressLine1: setAddressLine1,
				addressLine2: setAddressLine2,
				city: setCity,
				citizenshipStatus: setCitizenshipType,
				creditScore: setCreditScore,
				dob: setDOB,
				employmentStatus: setEmploymentStatus,
				firstName: setFirstName,
				highestDegree: setEducationRating,
				jobTitle: setJobTitle,
				lastName: setLastName,
				mfiCreditScore: setCreditScore,
				monthlyRent: setMonthlyRentPayment,
				state: setState,
				veteran: setIsVeteran,
				zipcode: setZipCode
			};

			const userProfileFields = Object.keys(data);

			try {
				userProfileFields.forEach((userProfileField) => {
					const currentData = data[userProfileField];
					const setUpdater = WANTED_KEY_SET_MAPPING[userProfileField];

					if (FIELDS_TO_IGNORE.has(userProfileField)) return;
					if (userProfileField === 'address') {
						const addressRelatedFieldsWithoutNullValues =
							getNonNullValues(currentData);
						const addressRelatedFields = Object.keys(
							addressRelatedFieldsWithoutNullValues
						);
						const addressFieldsToIgnore = new Set([
							'addressId',
							'marketValue',
							'assedValue'
						]);
						const requiredAddressRelatedFields =
							addressRelatedFields.filter(
								(fieldName) =>
									!addressFieldsToIgnore.has(fieldName)
							);
						requiredAddressRelatedFields.forEach(
							(addressRelatedField) => {
								const currentAddressRelatedData =
									currentData[addressRelatedField];
								const addressRelatedUpdater =
									WANTED_KEY_SET_MAPPING[addressRelatedField];
								if (addressRelatedField === 'addressId') return;
								addressRelatedUpdater(
									currentAddressRelatedData
								);
							}
						);
						return;
					}

					if (userProfileField === 'dob') {
						const convertedDate =
							convertDateToDatePickerFormat(currentData);

						setUpdater(convertedDate);
						return;
					}

					if (userProfileField === 'veteran') {
						if (currentData) {
							setUpdater('Yes');
						} else {
							setUpdater('No');
						}
						return;
					}
					setUpdater(currentData);
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}, [data]);

	useEffect(() => {
		if (userLiabilityIsSuccess) {
			const personalAndCreditCardLiabilities = userLiabilityData.filter(
				({ tradeLineType }) =>
					tradeLineType === PERSONAL || tradeLineType === CREDITCARD
			);

			const totalAutoLiabilitiesSum = getSumOfFieldBasedOnTradeLine(
				AUTO,
				userLiabilityData,
				'outstandingBalance'
			);

			const totalMortgageLiabilitiesSum = getSumOfFieldBasedOnTradeLine(
				MORTGAGE,
				userLiabilityData,
				'outstandingBalance'
			);

			const totalStudentliabilitiesSum = getSumOfFieldBasedOnTradeLine(
				STUDENT,
				userLiabilityData,
				'outstandingBalance'
			);

			// This is actually just creditCard and perssonal
			const totalOtherliabilitiesSum =
				personalAndCreditCardLiabilities.reduce(
					(acc, { outstandingBalance }) => acc + outstandingBalance,
					0
				);

			const allTradelinesBesidesCreditCard = userLiabilityData.filter(
				({ tradeLineType }) => tradeLineType !== CREDITCARD
			);

			const totalMonthlyDebt = getSumOfFieldBasedOnTradeLine(
				ALL,
				allTradelinesBesidesCreditCard,
				'monthlyPay'
			);

			setTotalHomeLiabilities(totalMortgageLiabilitiesSum);
			setTotalAutoLiabilities(totalAutoLiabilitiesSum);
			setTotalStudentLiabilities(totalStudentliabilitiesSum);
			setTotalOtherLiabilities(totalOtherliabilitiesSum);
			setTotalMonthlyDebtPayment(totalMonthlyDebt);
		}
	}, [userLiabilityData]);

	useEffect(() => {
		if (userIncomeIsSuccess) {
			try {
				setTotalAnnualIncome(+userIncomeData.totalAnnualIncome);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}, [userIncomeData]);

	const requiredFormValues = [
		addressLine1,
		city,
		citizenshipType,
		creditScore,
		isVeteran,
		monthlyRentPayment,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(requiredFormValues),
		requiredFormValues
	);

	const submitReviewData = (event) => {
		event.preventDefault();

		const formattedIsVeteran = isVeteran === 'Yes';
		const formattedCreditScore = Number.parseInt(creditScore, 10);
		const formattedRent = Number.parseInt(monthlyRentPayment, 10);

		const userDetails = {
			addressLine1,
			addressLine2,
			city,
			citizenshipStatus: citizenshipType,
			creditScore: formattedCreditScore,
			employer,
			employmentStatus,
			firstName,
			highestDegree: educationRating,
			isVeteran: formattedIsVeteran,
			jobTitle,
			lastName,
			monthlyRent: formattedRent,
			phoneNumber: data?.phoneNumber,
			state,
			zipcode: zipCode
		};

		updateProfileData.mutate(userDetails, {
			onSuccess: () => {
				navigate(CLIENT_SORAWALLET_ROUTE);
			}
		});
	};

	if (isLoading || userLiabilityIsLoading || userIncomeIsLoading) {
		return (
			<Box
				sx={{
					height: '100vh',
					width: '100%',
					overflow: 'auto',
					marginLeft: 2
				}}
			>
				<Helmet>
					<title>Review</title>
				</Helmet>

				<Typography variant="h1Gascogne" component="h1" marginTop={6}>
					Review
				</Typography>

				<Typography variant="body1" marginTop={4}>
					Review and make any changes to your liabilities, income, and
					assets to complete your Sora onboarding.
				</Typography>

				<Loader size={60} boxSX={{ marginTop: 10 }} />
			</Box>
		);
	}

	return (
		<Box
			sx={{
				height: '100vh',
				width: '100%',
				overflow: 'auto',
				marginLeft: 2
			}}
		>
			<Helmet>
				<title>Review</title>
			</Helmet>

			<Typography variant="h1Gascogne" component="h1" marginTop={4}>
				Review
			</Typography>

			<Typography variant="body1" marginTop={2}>
				Review and make any changes to your liabilities, income, and
				assets to complete your Sora onboarding.
			</Typography>

			<Box
				component="form"
				noValidate
				autoComplete="off"
				sx={{ marginRight: 2 }}
			>
				<Grid container columnSpacing={2} marginTop={4}>
					{/* ROW 1 */}
					<Grid item xs={6}>
						<TextInput
							disabled
							label="First name"
							value={firstName}
							onChange={setFirstName}
							type={TEXT}
							sx={{ opacity: 0.6 }}
							subLabel="Disabled"
						/>
					</Grid>

					<Grid item xs={6}>
						<TextInput
							disabled
							label="Last name"
							value={lastName}
							onChange={setLastName}
							type={TEXT}
							sx={{ marginBottom: 4, opacity: 0.6 }}
							subLabel="Disabled"
						/>
					</Grid>

					{/* ROW 2 */}
					<Grid item xs={6}>
						<Dropdown
							items={CITIZEN_STATUS_OPTIONS}
							selected={citizenshipType}
							onChange={setCitizenshipType}
							variant="outlined"
							label="Citizenship status"
							sx={{ marginBottom: 4 }}
						/>
					</Grid>

					<Grid item xs={6}>
						<StandardDatePicker
							disabled
							label="Date of birth"
							labelSx={{ opacity: 0.6 }}
							helperText="Disabled"
							onChange={setDOB}
							value={dob}
							inputProps={{
								tabIndex: '3'
							}}
							sx={{ opacity: 0.6 }}
						/>
					</Grid>

					{/* Address Rows */}
					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={6} />

					{/* ROW 4 */}
					<Grid item xs={6} sx={{ marginTop: 4 }}>
						<TextInput
							label="Credit score"
							value={creditScore}
							onChange={setCreditScore}
							type={NUMBER}
						/>
					</Grid>

					<Grid item xs={6} sx={{ marginTop: 4 }}>
						<CurrencyTextInput
							label="Monthly rent payment"
							value={monthlyRentPayment}
							onChange={setMonthlyRentPayment}
							helperText="If applicable. Optional"
						/>
					</Grid>

					{/* ROW 5 */}
					<Grid item xs={6}>
						<Dropdown
							items={EMPLOYMENT_STATUS_OPTIONS}
							selected={employmentStatus}
							onChange={setEmploymentStatus}
							variant="outlined"
							label="Employment status"
							sx={{ marginBottom: 4 }}
						/>
					</Grid>

					<Grid item xs={6}>
						<Dropdown
							items={EDUCATION_LEVEL_OPTIONS}
							selected={educationRating}
							onChange={setEducationRating}
							variant="outlined"
							label="Highest degree obtained"
						/>
					</Grid>

					{/* ROW 6 */}
					{/*
                    <Grid item xs={5}>
                    <TextInput
                    label="If employed, what is your job title?"
                    value={jobTitle}
                    onChange={setJobTitle}
                    helperText="optional"
                    type={TEXT}
                    sx={{ marginBottom: 4 }}
                    />
                    </Grid>
                    */}

					<Grid item xs={6}>
						<TextInput
							inputProps={{
								'data-test': 'employer'
							}}
							label="Employer"
							value={employer}
							onChange={setEmployer}
							type={TEXT}
							subLabel="Optional."
						/>
					</Grid>

					{/* ROW 7 */}
					<Grid item xs={6} sx={{ marginBottom: 4 }}>
						<Dropdown
							items={VETERAN_STATUS_OPTIONS}
							selected={isVeteran}
							onChange={setIsVeteran}
							variant="outlined"
							label="US Military Service?"
							sx={{ marginBottom: 4 }}
						/>
					</Grid>

					{/* ROW 8 */}
					<Grid item xs={6}>
						<CurrencyTextInput
							disabled
							label="Total home liabilities"
							value={totalHomeLiabilities}
							sx={{ opacity: 0.6 }}
						/>
					</Grid>

					<Grid item xs={6} marginBottom={4}>
						<CurrencyTextInput
							disabled
							label="Total student liabilities"
							value={totalStudentLiabilities}
							sx={{ opacity: 0.6 }}
						/>
					</Grid>

					{/* ROW 9 */}
					<Grid item xs={6}>
						<CurrencyTextInput
							disabled
							label="Total auto liabilities"
							value={totalAutoLiabilities}
							sx={{ opacity: 0.6 }}
						/>
					</Grid>

					<Grid item xs={6} marginBottom={4}>
						<CurrencyTextInput
							disabled
							label="Total other liabilities"
							value={totalOtherLiabilities}
							sx={{ opacity: 0.6 }}
						/>
					</Grid>

					{/* ROW 10 */}
					<Grid item xs={6}>
						<CurrencyTextInput
							disabled
							label="Total monthly debt payment"
							value={totalMonthlyDebtPayment}
							sx={{ opacity: 0.6 }}
						/>
					</Grid>

					<Grid item xs={6} marginBottom={4}>
						<CurrencyTextInput
							disabled
							label="Total annual income"
							value={totalAnnualIncome}
							sx={{ opacity: 0.6 }}
						/>
					</Grid>

					{/* ROW 11 */}
					<Grid item xs={12} sx={{ marginTop: 4, marginBottom: 30 }}>
						{updateProfileData.isLoading && <Loader />}
						{!updateProfileData.isLoading && (
							<PrimaryButton
								data-test="next"
								disabled={!isFormReady}
								onClick={submitReviewData}
							>
								Next
							</PrimaryButton>
						)}
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default OnboardingReviewForm;
