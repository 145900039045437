import { Box, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import DefaultTab from 'components/Tabs/DefaultTab';
import LoanComparison from 'components/Analytics/LoanComparison';
import MarginalDollar from 'components/Analytics/MarginalDollar/MarginalDollar';
import TabPanel from 'components/Tabs/TabPanel';

const PANEL_TOP_PADDING = 4;

function AnalyticsTabs({ tabPanelSelected, setTabPanelSelected }) {
	return (
		<Box>
			<Box>
				<Tabs value={tabPanelSelected} onChange={setTabPanelSelected}>
					<DefaultTab label="Marginal Dollar" index={0} />
					<DefaultTab label="Loan Comparison" index={1} />
					<DefaultTab label="Liquidity" index={2} disabled />
				</Tabs>
			</Box>

			<TabPanel
				value={tabPanelSelected}
				index={0}
				sx={{ paddingTop: PANEL_TOP_PADDING }}
			>
				<MarginalDollar />
			</TabPanel>
			<TabPanel
				value={tabPanelSelected}
				index={1}
				sx={{ paddingTop: PANEL_TOP_PADDING }}
			>
				<LoanComparison />
			</TabPanel>

			<TabPanel
				value={tabPanelSelected}
				index={2}
				sx={{ paddingTop: PANEL_TOP_PADDING }}
			>
				<div />
			</TabPanel>
		</Box>
	);
}

AnalyticsTabs.propTypes = {
	tabPanelSelected: PropTypes.number.isRequired,
	setTabPanelSelected: PropTypes.func.isRequired
};

export default AnalyticsTabs;
