import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import { PrimaryButton, SecondaryButton } from 'components/Button/Button';
import Loader from 'components/Loader';

// Our Queries
import useMutateExecuteLoan from 'hooks/useMutateExecuteLoan';
import useGetProfile from 'hooks/useGetProfile';

// Our Constants
const SUPPORTED_OFFER_TYPES = ['new_loan', 'cash_out', 'refinance'];

function OffersColumnItem({
	isSoraChoice,
	lenderLogoUrl,
	offerType,
	offerId,
	modalCallBack,
	ranking
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const { mutate: executeLoan, isLoading } = useMutateExecuteLoan();

	const handleLoanExecution = () => {
		executeLoan(offerId);
	};

	const { isLoading: isRoleLoading, data: profileData } = useGetProfile();

	const renderButton = () => {
		if (isLoading || isRoleLoading)
			return <Loader boxSX={{ width: 162, justifyContent: 'center' }} />;

		const { role } = profileData;

		if (role === 'CLIENT')
			return (
				<PrimaryButton onClick={() => handleLoanExecution()}>
					Execute Loan
				</PrimaryButton>
			);

		return (
			<PrimaryButton onClick={() => modalCallBack(true)}>
				Contact Client
			</PrimaryButton>
		);
	};

	const height = offerType === 'cash_out' ? 820 : 900;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height,
				zIndex: 2
			}}
		>
			{isSoraChoice && (
				<PrimaryButton
					sx={{
						width: 188,
						height: 36,
						position: 'relative',
						top: '2%',
						zIndex: 3,
						borderRadius: '10px',
						cursor: 'auto'
					}}
				>
					Option A
				</PrimaryButton>
			)}

			{!isSoraChoice && (
				<SecondaryButton
					sx={{
						width: 188,
						height: 36,
						color: primary.soraBlue,
						backgroundColor: primary.white,
						border: `2px solid ${primary.soraBlue}`,
						borderRadius: '10px',
						position: 'relative',
						top: '2%',
						zIndex: 3,
						'&:hover': {
							width: 188,
							height: 36,
							color: primary.soraBlue,
							backgroundColor: primary.white,
							border: `2px solid ${primary.soraBlue}`,
							borderRadius: '10px',
							position: 'relative',
							top: '2%',
							zIndex: 3,
							cursor: 'auto'
						}
					}}
				>
					Option {ranking}
				</SecondaryButton>
			)}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					border: `2px solid ${primary.soraBlue}`,
					borderRadius: '10px',
					alignItems: 'center',
					padding: 6,
					height: 900
				}}
			>
				<Box
					sx={{
						height: 80,
						width: 140,
						display: 'flex',
						alignItems: 'center',
						marginBottom: 1
					}}
				>
					<Box
						component="img"
						src={lenderLogoUrl}
						sx={{ maxHeight: 80, maxWidth: 140 }}
					/>
				</Box>
				{renderButton()}
			</Box>
		</Box>
	);
}

OffersColumnItem.propTypes = {
	isSoraChoice: PropTypes.bool,
	offerType: PropTypes.oneOf(SUPPORTED_OFFER_TYPES).isRequired,
	offerId: PropTypes.number.isRequired,
	modalCallBack: PropTypes.func
};

OffersColumnItem.defaultProps = {
	isSoraChoice: false,
	modalCallBack: () => true
};

export default OffersColumnItem;
