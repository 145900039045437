import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import RateInput from 'components/Input/RateInput';
import TextInput from 'components/Input/TextInput';

// our Utils
import { dollarFormatter, roundToDecimal } from 'shared/utils';

// Text Types
import { TEXT } from 'components/Input/Types';

// Constants
const defaultTableStyles = {
	width: '95%'
};

const StyledTableCell = styled(TableCell)({
	height: 89
});

function AnalyticsTable({
	analyticsOffer,
	bestOfferBalance,
	setBestOfferBalance,
	bestOfferInterestRate,
	setBestOfferInterestRate,
	bestOfferTenure,
	setBestOfferTenure
}) {
	const { currentLoan } = analyticsOffer;

	const { balance, interestRate, remainingMonth } = currentLoan;

	return (
		<TableContainer component={Paper} elevation={4} sx={defaultTableStyles}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<StyledTableCell />
						<StyledTableCell>Current Loan</StyledTableCell>
						<StyledTableCell>Best Offer</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow
						sx={{
							backgroundColor: '#809cdb1a							'
						}}
					>
						<StyledTableCell>Balance</StyledTableCell>
						<StyledTableCell>
							{dollarFormatter.format(balance ?? 0)}
						</StyledTableCell>
						<StyledTableCell>
							<CurrencyTextInput
								label=""
								type="number"
								value={bestOfferBalance}
								onChange={setBestOfferBalance}
								sx={{ height: 50 }}
							/>
						</StyledTableCell>
					</TableRow>
					<TableRow>
						<StyledTableCell>Rate</StyledTableCell>
						<StyledTableCell>{`${
							roundToDecimal(interestRate) ?? 0
						}%`}</StyledTableCell>
						<StyledTableCell>
							<RateInput
								type="number"
								rate={bestOfferInterestRate}
								setRate={setBestOfferInterestRate}
								subLabel=""
								label=""
							/>
						</StyledTableCell>
					</TableRow>
					<TableRow
						sx={{
							backgroundColor: '#809cdb1a							'
						}}
					>
						<StyledTableCell>Loan Remaining Years</StyledTableCell>
						<TableCell>
							{roundToDecimal(remainingMonth / 12) ?? ''}
						</TableCell>
						<TableCell>
							<TextInput
								type={TEXT}
								value={bestOfferTenure}
								onChange={setBestOfferTenure}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default AnalyticsTable;
