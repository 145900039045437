import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

// our Assets
import avgScoreIcon from 'assets/icons/miscellaneous/avgScoreIcon.svg';
import dollarIcon from 'assets/icons/carousel_icons/dollarIcon.svg';
import loanInquiryIcon from 'assets/icons/carousel_icons/request_loan_icon.svg';
import piggyIcon from 'assets/icons/carousel_icons/piggyIcon.svg';
import pencilIcon from 'assets/icons/carousel_icons/PencilAndPaper.svg';
import totalLoansIcon from 'assets/icons/carousel_icons/totalLoansIcon.svg';

// Our Components
import StandardCarousel from 'components/Carousel/StandardCarousel';
import HighlightCard from 'components/FADashboard/HighlightCarousel/HightlightCard';

// Tooltip Data
import highlightCarouselTooltips from 'components/FADashboard/HighlightCarousel/highlightCarouselTooltips.json';

// Our Routes
import { LOAN_INQUIRY_ROUTE } from 'routes';

// Our Utils
import { numbroAveragedToDecimal } from 'shared/utils';

// TODO change carousel buttons from default to our arrows
// TODO move carousel buttons from default positions to bottom right.
// Carousel package link - https://www.npmjs.com/package/react-multi-carousel

function HighlightCarousel({
	averageSoraScore,
	untappedSavings,
	upsidePotential,
	totalLoansUnderManagement
}) {
	const SoraTheme = useTheme();
	const { carouselGradient, primary } = SoraTheme.palette;

	const navigate = useNavigate();

	const averageSoraScoreToRender =
		averageSoraScore === 'F' ? 'NA' : averageSoraScore;

	const formattedTotalLoansUnderManagement = useMemo(
		() => numbroAveragedToDecimal(totalLoansUnderManagement ?? 0),
		[totalLoansUnderManagement]
	);

	const formattedClientUntappedSavings = useMemo(
		() => numbroAveragedToDecimal(untappedSavings ?? 0),
		[untappedSavings]
	);

	const formattedUpsidePotential = useMemo(
		() => numbroAveragedToDecimal(upsidePotential ?? 0),
		[upsidePotential]
	);

	const hightlightCards = useMemo(() => {
		const cards = [
			{
				background: carouselGradient.gradient1,
				buttonText: 'Request now',
				cardHeading: 'Price a Loan',
				cardContentValue: (
					<Typography variant="body2" sx={{ color: primary.indigo }}>
						Get offers on new <br />
						consumer or
						<br />
						commercial loans
					</Typography>
				),
				image: loanInquiryIcon,
				headingColor: SoraTheme.palette.primary.indigo,
				withToolTip: false,
				onClick: () => navigate(LOAN_INQUIRY_ROUTE)
			},
			{
				background: carouselGradient.gradient2,
				cardHeading: 'Debt Tolerance Assessment',
				cardContentVariant: 'body2',
				cardContentValue: (
					<Typography variant="body2" sx={{ color: primary.white }}>
						Assess client&apos;s
						<br />
						comfort with debt
					</Typography>
				),
				image: dollarIcon,
				headingColor: primary.white,
				type: 'debtTolereanceAssessment'
			},
			{
				background: carouselGradient.gradient3,
				cardHeading: 'Total Upside Potential',
				cardContentValue: `$${formattedUpsidePotential}`,
				image: pencilIcon,
				headingColor: primary.indigo,
				type: 'upsidePotential'
			},
			{
				background: carouselGradient.gradient4,
				cardHeading: `Total Untapped Savings`,
				cardContentValue: `$${formattedClientUntappedSavings}`,
				image: piggyIcon,
				headingColor: primary.indigo,
				type: 'untappedSavings'
			},
			{
				background: carouselGradient.gradient2,
				cardHeading: (
					<Typography variant="h6" sx={{ color: primary.white }}>
						Total Liabilities Under
						<br /> Management
					</Typography>
				),
				cardContentValue: `$${formattedTotalLoansUnderManagement}`,
				image: totalLoansIcon,
				headingColor: primary.white,
				type: 'totalLiabilitiesUnderManagement'
			},
			{
				background: `linear-gradient(180deg, ${primary.iceBlue} 0%, rgba(151, 216, 232, 0.22) 100%)`,
				cardHeading: 'Average Sora Score',
				cardContentValue: averageSoraScoreToRender,
				image: avgScoreIcon,
				type: 'soraScore'
			}
		];
		return cards.map(
			({
				type,
				background,
				buttonText,
				cardHeading,
				cardHeadingVariant,
				cardContentValue,
				cardContentVariant,
				headingColor,
				image,
				imageSX,
				withToolTip,
				onClick
			}) => {
				const uniqueIdentifier = uuidv4();
				const tooltipText = highlightCarouselTooltips[type];
				return (
					<HighlightCard
						key={uniqueIdentifier}
						background={background}
						buttonText={buttonText}
						cardHeading={cardHeading}
						cardHeadingVariant={cardHeadingVariant}
						cardContentValue={cardContentValue}
						cardContentVariant={cardContentVariant}
						headingColor={headingColor}
						image={image}
						imageSX={imageSX}
						tooltipText={tooltipText}
						withToolTip={withToolTip ?? true}
						onClick={onClick}
					/>
				);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		averageSoraScoreToRender,
		formattedClientUntappedSavings,
		formattedTotalLoansUnderManagement,
		formattedUpsidePotential
	]);

	return <StandardCarousel carouselItems={hightlightCards} />;
}

export default HighlightCarousel;
