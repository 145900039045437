// Our Components
import SimpleAssetsSection from 'components/Analytics/Assumptions/SimpleAssetsSection';

function SimpleAssetsTable({
	investmentAccountPresentValue,
	setInvestmentAccountPresentValue,
	investmentAnnualGrowth,
	setInvestmentAnnualGrowth,
	propertyPresentValue,
	setPropertyPresentValue,
	propertyGrowthRate,
	setPropertyGrowthRate
}) {
	return (
		<>
			<SimpleAssetsSection
				sx={{ marginTop: 3, marginBottom: 2 }}
				title="Investment Account"
				presentValue={investmentAccountPresentValue}
				setPresentValue={setInvestmentAccountPresentValue}
				annualGrowth={investmentAnnualGrowth}
				setAnnualGrowth={setInvestmentAnnualGrowth}
			/>

			<SimpleAssetsSection
				sx={{ marginBottom: 2 }}
				title="Property"
				presentValue={propertyPresentValue}
				setPresentValue={setPropertyPresentValue}
				annualGrowth={propertyGrowthRate}
				setAnnualGrowth={setPropertyGrowthRate}
			/>
		</>
	);
}

export default SimpleAssetsTable;
