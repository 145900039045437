import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useEffect, useState, useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader/index';
import MarginalDollarTable from 'components/Analytics/MarginalDollar/MarginalDollarTable';
import MarginalDollarOverflowDropdown from 'components/Analytics/MarginalDollar/MarginalDollarOverflowDropdown';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import Results from 'components/Analytics/Results/Results';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetWalletOverviewAsAdvisor from 'hooks/useGetWalletOverviewAsAdvisor';
import useQueryGetAssumptions from 'hooks/analytics/assumptions/useQueryGetAssumptions';
import useMutateSaveAssumptions from 'hooks/analytics/assumptions/useMutateSaveAssumptions';
import useMutateGetAnalysis from 'hooks/analytics/calculations/useMutateGetAnalysis';

// Our Context
import AnalyticsContext from 'context/AnalyticsContext';

// Our Selectors
import selectLiabilitiesDataFromWalletOverview from 'selectors/selectLiabilitiesDataFromWalletOverview';

// Our Utils
import {
	dataIsValid,
	isSubmissionReady,
	isFormValueReady,
	roundToDecimal
} from 'shared/utils';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

// Constants
import { CREDITCARD } from 'shared/constants';

// Local Constants
const YEARS_FROM_NOW_OPTIONS = Array.from(
	{ length: 6 },
	(_, index) => `${(index + 1) * 5} years from now`
);

// Local Constants
const OVERFLOW_ASSET_CATEGORIES_SIMPLE = ['Investment accounts', 'Property'];
const OVERFLOW_ASSET_CATEGORIES_DETAILED = [
	'Brokerage',
	'Non-Brokerage',
	'House',
	'Other'
];

function MarginalDollar() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const { state } = useLocation();

	const { setIsModalOpen, setModalError } = useContext(AnalyticsContext);

	const prePopulatedClientData = state?.clientSelected;

	const [netIncome, setNetIncome] = useState('1');

	const [projectedYearsFromNow, setProjectedYearsFromNow] =
		useState('20 years from now');

	const [overflowAssetCategory, setOverflowAssetCategory] = useState({
		selected: 'Investment accounts',
		overflowAssetCategoryOptions: OVERFLOW_ASSET_CATEGORIES_SIMPLE
	});

	const [rowsState, setRowsState] = useState([]);

	const isMissingClient = !dataIsValid(prePopulatedClientData);

	// Data Hooks
	const {
		isLoading: isWalletLoading,
		isSuccess: isWalletSuccess,
		data
	} = useGetWalletOverviewAsAdvisor(
		prePopulatedClientData?.id,
		true,
		selectLiabilitiesDataFromWalletOverview
	);

	const {
		isLoading: isLoadingAssumptions,
		data: assumptionsData,
		isSuccess: isSuccessAssumptions
	} = useQueryGetAssumptions(prePopulatedClientData?.id);

	const getAnalysis = useMutateGetAnalysis();
	const saveAnalysis = useMutateSaveAssumptions();

	useEffect(() => {
		const hasLiabilitiyAssumptions =
			dataIsValid(assumptionsData?.liabilities) &&
			assumptionsData.liabilities.length > 0;

		const hasNetIncomeAssumptions = dataIsValid(assumptionsData?.netIncome);

		const hasProjectionEndYearAssumptions = dataIsValid(
			assumptionsData?.endYear
		);

		const hasOverflowAssetCategory = dataIsValid(
			assumptionsData?.overflowAssetCategory
		);

		if (hasNetIncomeAssumptions) setNetIncome(assumptionsData.netIncome);
		if (hasProjectionEndYearAssumptions)
			setProjectedYearsFromNow(
				`${assumptionsData.endYear} years from now`
			);

		if (hasOverflowAssetCategory) {
			const currentOverflowAssetCategory =
				assumptionsData.overflowAssetCategory;

			const isSimpleCategory = OVERFLOW_ASSET_CATEGORIES_SIMPLE.includes(
				currentOverflowAssetCategory
			);

			if (isSimpleCategory) {
				setOverflowAssetCategory({
					selected: currentOverflowAssetCategory,
					overflowAssetCategoryOptions:
						OVERFLOW_ASSET_CATEGORIES_SIMPLE
				});
			} else {
				setOverflowAssetCategory({
					selected: currentOverflowAssetCategory,
					overflowAssetCategoryOptions:
						OVERFLOW_ASSET_CATEGORIES_DETAILED
				});
			}
		}

		if (hasLiabilitiyAssumptions) {
			setRowsState(
				assumptionsData.liabilities.map((liability) => {
					const {
						type,
						lender,
						presentValue,
						firstAPR,
						firstYears,
						twoPeriods,
						interestOnly,
						secondAPR,
						secondYears
					} = liability;

					const yearsInFirstPeriodWithFallBack =
						firstYears === 0 ? '' : firstYears;

					return {
						lender,
						liabilityType: type,
						presentValue,
						firstPeriodAnnualGrowth: `${firstAPR ?? ''}`,
						yearsInFirstPeriod: `${
							yearsInFirstPeriodWithFallBack ?? ''
						}`,
						twoPeriods: `${twoPeriods ? 'Yes' : 'No'}`,
						interestOnly: `${interestOnly ? 'Yes' : 'No'}`,
						secondPeriodAnnualGrowth: `${secondAPR ?? ''}`,
						yearsInSecondPeriod: `${secondYears ?? ''}`
					};
				})
			);
		} else if (isWalletSuccess) {
			setRowsState(
				data.map((liability) => {
					const {
						balanceTenureMonth,
						interestRate,
						lender,
						outstandingBalance: presentValue,
						tradeLineType
					} = liability;

					const normalizedTradeLineType =
						tradeLineType === CREDITCARD
							? 'Credit Card'
							: normalizeLoanType(tradeLineType);

					const tenureInYears = roundToDecimal(
						balanceTenureMonth / 12,
						1
					);

					return {
						lender,
						liabilityType: normalizedTradeLineType,
						presentValue,
						firstPeriodAnnualGrowth: `${interestRate}`,
						yearsInFirstPeriod: `${
							tenureInYears === 0 ? '' : tenureInYears
						}`,
						twoPeriods: 'No',
						interestOnly: 'No',
						secondPeriodAnnualGrowth: '0',
						yearsInSecondPeriod: '0'
					};
				})
			);
		}
	}, [isWalletSuccess, data, state, assumptionsData]);

	useEffect(() => {
		// if client changed
		getAnalysis.reset();
	}, [prePopulatedClientData?.id]);

	const isTableReady = rowsState.every((row) => {
		const rowFields = Object.values(row);

		return (
			isSubmissionReady(rowFields) &&
			rowFields.every((field) => field !== 'undefined')
		);
	});

	const isNetIncomeReady = isFormValueReady(netIncome) && netIncome > 0;

	const isPageReady = useMemo(
		() => isTableReady && isNetIncomeReady,
		[isTableReady, netIncome]
	);

	const hasNoLiabilities = rowsState.length === 0;

	const handleOverflowAssetCategoryChange = (selectedCategory) => {
		const isSimpleCategory =
			OVERFLOW_ASSET_CATEGORIES_SIMPLE.includes(selectedCategory);

		if (isSimpleCategory) {
			setOverflowAssetCategory({
				selected: selectedCategory,
				overflowAssetCategoryOptions: OVERFLOW_ASSET_CATEGORIES_SIMPLE
			});
		} else {
			setOverflowAssetCategory({
				selected: selectedCategory,
				overflowAssetCategoryOptions: OVERFLOW_ASSET_CATEGORIES_DETAILED
			});
		}
	};

	const submitAnalysis = () => {
		const hasLiabilities = rowsState.length > 0;

		if (hasLiabilities) {
			// Extract num from string and convert to number
			const formattedProjectionEndYear = Number.parseInt(
				projectedYearsFromNow,
				10
			);

			const isSimpleMode = assumptionsData?.assumptionsMode === 'Simple';

			const formattedLiabilities = rowsState.map((row) => {
				const {
					liabilityType,
					lender,
					presentValue,
					firstPeriodAnnualGrowth,
					yearsInFirstPeriod,
					twoPeriods,
					interestOnly,
					secondPeriodAnnualGrowth,
					yearsInSecondPeriod
				} = row;

				const formattedRow = {
					type: liabilityType,
					lender,
					adjusted: false,
					presentValue,
					firstAPR: +firstPeriodAnnualGrowth,
					firstYears: +yearsInFirstPeriod,
					twoPeriods: twoPeriods === 'Yes',
					interestOnly: interestOnly === 'Yes',
					secondAPR: +secondPeriodAnnualGrowth,
					secondYears: +yearsInSecondPeriod
				};

				return formattedRow;
			});

			const adjustedAssetsData = assumptionsData?.assets.filter(
				(asset) => {
					if (isSimpleMode) {
						return (
							asset.type === 'Investment accounts' ||
							asset.type === 'Property'
						);
					}

					return (
						asset.type !== 'Investment accounts' &&
						asset.type !== 'Property'
					);
				}
			);

			const analysisPayload = {
				...assumptionsData,
				liabilities: formattedLiabilities,
				netIncome: +netIncome,
				overflowAssetCategory: overflowAssetCategory.selected,
				endYear: formattedProjectionEndYear
			};

			getAnalysis.mutate(
				{ ...analysisPayload, assets: adjustedAssetsData },
				{
					onError: () => {
						setIsModalOpen(true);
						setModalError(
							'Please confirm assumptions and press the submit button below'
						);
					}
				}
			);

			saveAnalysis.mutate(analysisPayload);
		}
	};

	const isLoading =
		isWalletLoading ||
		isLoadingAssumptions ||
		getAnalysis.isLoading ||
		saveAnalysis.isLoading;

	const isSuccess = isWalletSuccess && isSuccessAssumptions;

	if (isLoading)
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					backgroundColor: 'white',
					padding: 7,
					borderRadius: 3
				}}
			>
				<Typography variant="h2Gascogne" sx={{ color: primary.indigo }}>
					Marginal Dollar Analyzer
				</Typography>
				<Typography variant="body2">
					Learn how to best deploy your net income.
				</Typography>

				<LoadingSkeleton sx={{ marginTop: 2, width: '100%' }} />

				<LoadingSkeleton sx={{ marginTop: 2, width: '100%' }} />
			</Box>
		);

	if (isSuccess) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					backgroundColor: 'white',
					padding: 7,
					borderRadius: 3
				}}
			>
				<Typography variant="h2Gascogne" sx={{ color: primary.indigo }}>
					Marginal Dollar Analyzer
				</Typography>

				<Typography variant="body2">
					Learn how to best deploy your net income.
				</Typography>

				<Grid container sx={{ marginTop: 3, marginBottom: 3 }}>
					<Grid item xs={12} sm={4}>
						<CurrencyTextInput
							variant="body2"
							label="Amount available to invest:"
							value={netIncome}
							error={!isNetIncomeReady}
							helperText="Amount available to invest must be greater than $0"
							onChange={setNetIncome}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography component="h6" variant="subtitleGascongne">
							Liabilities
						</Typography>
					</Grid>

					<Grid item xs={12} sx={{ marginBottom: 2 }}>
						{!isMissingClient && (
							<MarginalDollarTable
								selectedClient={prePopulatedClientData}
								rowsState={rowsState}
								setRowsState={setRowsState}
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={4} xl={3}>
						<Dropdown
							label="Projection end year:"
							labelVariant="body2"
							items={YEARS_FROM_NOW_OPTIONS}
							onChange={setProjectedYearsFromNow}
							selected={projectedYearsFromNow}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} xl={3}>
						<MarginalDollarOverflowDropdown
							overflowAssetCategory={overflowAssetCategory}
							setOverflowAssetCategory={
								handleOverflowAssetCategoryChange
							}
						/>
					</Grid>

					<Grid item xs={6} />

					<Grid item xs={12} md={8} xl={6}>
						<Typography variant="caption">
							Note: If the value of your net income is greater
							than the value of one of your liabilities, when the
							model tests the impact of paying off that liability,
							it will route the rest of your net income to this
							asset.
						</Typography>
					</Grid>
				</Grid>

				{getAnalysis.isLoading && <Loader boxSX={{ marginTop: 4 }} />}

				{!getAnalysis.isLoading && (
					<TertiaryButton
						sx={{ marginTop: 4, padding: 2 }}
						isDisabled={hasNoLiabilities || !isPageReady}
						onClick={submitAnalysis}
					>
						Submit
					</TertiaryButton>
				)}

				{getAnalysis.isSuccess && (
					<Results
						data={getAnalysis.data}
						reset={() => getAnalysis.reset()}
					/>
				)}
			</Box>
		);
	}
}

export default MarginalDollar;
