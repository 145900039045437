import { Box } from '@mui/material';

// our components
import ChartItem from 'components/SoraWallet/ChartItem';

// our utils
import { roundToDecimal } from 'shared/utils';

function Chart({ bestOfferLoan, currentLoan }) {
	// TODO Conditionally render a char item based off props
	// Expect an object per Chart Item with Label, variant, 2 separate int values

	const currentLoanYearsRemaining = currentLoan.remainingMonth
		? roundToDecimal(currentLoan.remainingMonth / 12)
		: 'N/A';
	const bestOfferLoanYearsRemaining = bestOfferLoan.remainingMonth
		? roundToDecimal(bestOfferLoan.remainingMonth / 12)
		: 'N/A';

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-evenly',
				marginBottom: 3
			}}
		>
			<ChartItem
				label="Total Cost of Debt"
				currentLoanValue={currentLoan.costOfDebt}
				newLoanValue={bestOfferLoan.costOfDebt}
				variant="dollar"
				sx={{ marginLeft: 2 }}
			/>
			<ChartItem
				label="Monthly Payment"
				currentLoanValue={currentLoan.monthlyPayment}
				newLoanValue={bestOfferLoan.monthlyPayment}
				variant="month"
			/>
			<ChartItem
				label="Years Remaining"
				currentLoanValue={currentLoanYearsRemaining}
				newLoanValue={bestOfferLoanYearsRemaining}
				variant="year"
			/>
		</Box>
	);
}

export default Chart;
