import { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import TextInput from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import SoraTextField from 'components/Input/TextArea';
import { NUMBER } from 'components/Input/Types';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';

// Constants
import {
	EMPLOYMENT_STATUS_OPTIONS,
	CASHOUT_OFFER_TYPE,
	MORTGAGE,
	MORTGAGE_LOAN_TENURE_OPTIONS,
	TYPES_OF_HOME_PROPERTIES
} from 'shared/constants';

function CashOutRefiForm({
	loanRequestId,
	refinancingTradelineId,
	additionalNotesValue,
	disableAutoComplete,
	setDisableAutoComplete,
	addressLine1Value,
	addressLine2Value,
	advisorEmail,
	advisorName,
	annualIncome,
	cityValue,
	clientId,
	emailAddressValue,
	employmentStatusValue,
	estimatedPurchaseAmountValue,
	currentInterestRateValue,
	desiredInterestTypeValue,
	remainingTermValue,
	estimatedValue,
	firstName,
	lastName,
	outstandingBalanceValue,
	ownershipTenureValue,
	postSuccessEvent,
	propertyTypeValue,
	primaryUseValue,
	requestedLoanAmount,
	stateValue,
	zipCodeValue
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	const sendGenericEmail = useMutateSendGenericEmail();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [tradelineId, setTradelineId] = useState('');

	// Pre-populated state items
	const [fullName, setFullName] = useState(fullNameValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [loanAmount, setLoanAmount] = useState(requestedLoanAmount || '');
	const [employmentStatus, setEmploymentStatus] = useState(
		employmentStatusValue || 'Employed'
	);
	const [propertyType, setPropertyType] = useState(propertyTypeValue || '');
	const [primaryUse, setPrimaryUse] = useState(primaryUseValue || '');
	const [currentInterestRate, setCurrentInterestRate] = useState(
		currentInterestRateValue || ''
	);
	const [remainingTerm, setRemainingTerm] = useState(
		remainingTermValue || ''
	);
	const [desiredInterestType, setDesiredInterestType] = useState(
		desiredInterestTypeValue || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState(
		estimatedPurchaseAmountValue || ''
	);
	const [outstandingBalance, setOutstandingBalance] = useState(
		outstandingBalanceValue || ''
	);

	const [ownershipTenure, setOwnershipTenure] = useState('');
	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [subject, setSubject] = useState('Cash Out Refinance Loan Request');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [
		propertyType,
		primaryUse,
		outstandingBalance,
		estimatedPurchaseValue,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const loanRequestDetailsRequiredFields = [
		propertyType,
		primaryUse,
		outstandingBalance,
		remainingTerm,
		desiredInterestType,
		totalAnnualIncome,
		estimatedPurchaseValue,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			),
		atLoanRequestDetailsUrl ? loanRequestDetailsRequiredFields : formData
	);

	useEffect(() => {
		if (outstandingBalanceValue) {
			setOutstandingBalance(outstandingBalanceValue.toString());
		}
		if (estimatedValue) {
			setEstimatedPurchaseValue(estimatedValue);
		}
		if (propertyTypeValue) {
			setPropertyType(propertyTypeValue);
		}
		if (cityValue) {
			setCity(cityValue);
		}
		if (stateValue) {
			setState(`${stateValue}`);
		}
		if (zipCodeValue) {
			setZipCode(zipCodeValue);
		}
		if (refinancingTradelineId) {
			setTradelineId(refinancingTradelineId);
		}
		if (desiredInterestTypeValue) {
			setDesiredInterestType(desiredInterestTypeValue);
		}
		if (remainingTermValue) {
			setRemainingTerm(remainingTermValue);
		}
		if (currentInterestRateValue) {
			setCurrentInterestRate(currentInterestRateValue);
		}
		if (ownershipTenureValue) {
			setOwnershipTenure(ownershipTenureValue);
		}
		if (addressLine1Value) {
			setAddressLine1(addressLine1Value);
		}
		if (addressLine2Value) {
			setAddressLine2(addressLine2Value);
		}
	}, [
		outstandingBalanceValue,
		estimatedValue,
		primaryUseValue,
		cityValue,
		stateValue,
		zipCodeValue,
		refinancingTradelineId,
		propertyTypeValue,
		desiredInterestTypeValue,
		remainingTermValue,
		currentInterestRateValue,
		ownershipTenureValue,
		addressLine1Value,
		addressLine2Value
	]);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor ${advisorName} has a CashOut Refinance Loan Request for one of their clients.
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a CashOut Refinance Loan Request for the following CLIENT:

			Client's name: ${fullName}
			Client's Email address: ${emailAddress}
			Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
			Client's Employment status: ${employmentStatus}
			Client's Total Annual Income: ${totalAnnualIncome}
			Request Amount of Cash Out Refi: ${loanAmount}
			Client's Ideal loan tenure: ${ownershipTenure}
			Client's Estimated Home Value: ${estimatedPurchaseValue}
			Client's Outstanding Mortgage Balance: ${outstandingBalance}
			Primary use of property: ${primaryUse}
			Property type: ${propertyType}
			Client's Current Mortgage Interest Rate: ${currentInterestRate}
			Client's remaining term of current loan ${remainingTerm}
			Client's desired interest type: ${desiredInterestType}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			emailAddress,
			city,
			state,
			zipCode,
			totalAnnualIncome,
			loanAmount,
			ownershipTenure,
			estimatedPurchaseValue,
			outstandingBalance,
			additionalNotes,
			employmentStatus,
			advisorName,
			advisorEmail,
			fullName,
			primaryUse,
			propertyType,
			currentInterestRate,
			remainingTerm,
			desiredInterestType,
			addressLine1,
			addressLine2
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setEmailAddress,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setTotalAnnualIncome,
						setLoanAmount,
						setOwnershipTenure,
						setEstimatedPurchaseValue,
						setOutstandingBalance,
						setAdditionalNotes,
						setEmploymentStatus,
						setSubject,
						setPrimaryUse,
						setPropertyType
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			primaryUse,
			propertyType,
			addressLine1,
			addressLine2,
			city,
			clientId,
			currentInterestRate: +currentInterestRate,
			employmentStatus,
			desiredInterestType,
			estimatedPurchaseValue: +estimatedPurchaseValue,
			firstName,
			lastName,
			offerType: CASHOUT_OFFER_TYPE,
			outstandingBalance,
			remainingTerm,
			ownershipTenure,
			loanAmount,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			primaryUse,
			propertyType,
			city,
			clientId,
			currentInterestRate,
			addressLine1,
			addressLine2,
			employmentStatus,
			desiredInterestType,
			estimatedPurchaseValue,
			firstName,
			lastName,
			offerType: CASHOUT_OFFER_TYPE,
			outstandingBalance,
			remainingTerm,
			ownershipTenure,
			loanAmount,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setEmailAddress,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setTotalAnnualIncome,
						setLoanAmount,
						setOwnershipTenure,
						setEstimatedPurchaseValue,
						setOutstandingBalance,
						setAdditionalNotes,
						setEmploymentStatus,
						setSubject,
						setPropertyType,
						setPrimaryUse
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const submitForm = (event) => {
		event.preventDefault();
		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Cash Out Refinance Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<Grid container spacing={2}>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Type of property input */}
						<Dropdown
							items={[
								'Single family home',
								'Apartment',
								'Townhouse',
								'Condominium',
								'Mobile home',
								'Multi-family home',
								'Bungalow',
								'Farmhouse'
							]}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label="Property type *"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="1"
							sx={{ marginBottom: 2 }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Primary use of property */}
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={primaryUse}
							onChange={setPrimaryUse}
							variant="outlined"
							label="Property use *"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="2"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Mortgage Outstanding Balance */}
						<OutstandingBalanceInput
							label="Current Mortgage Outstanding Balance *"
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
							inputProps={{
								tabIndex: '3',
								'data-test': 'outstandingBalance'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Estimated Purchase Value */}
						<CurrencyTextInput
							label="Estimated Purchase Value *"
							subLabel="Estimated price of home"
							value={estimatedPurchaseValue}
							onChange={setEstimatedPurchaseValue}
							inputProps={{
								tabIndex: '4',
								'data-test': 'estimatedPurchaseValue'
							}}
							sx={{ marginTop: 2 }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Interest Rate */}
						<RateInput
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							label="Current mortgage interest rate *"
							inputProps={{
								tabIndex: '5',
								'data-test': 'interestRateCurrentMortgage'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Remaining term */}
						<TextInput
							type={NUMBER}
							label={
								atLoanRequestDetailsUrl
									? 'Remaining loan term *'
									: 'Remaining loan term'
							}
							subLabel="Length of loan in years"
							onChange={setRemainingTerm}
							value={remainingTerm}
							inputProps={{
								tabIndex: '6',
								'data-test': 'Remaining loan term'
							}}
						/>
					</Grid>

					{/* Desired interest type, commented for ticket sora2-564
					<Grid item xs={12} md={6}>
						
						<Dropdown
							items={['Fixed', 'Variable']}
							selected={desiredInterestType}
							onChange={setDesiredInterestType}
							variant="outlined"
							label={
								atLoanRequestDetailsUrl
									? 'Desired interest type *'
									: 'Desired interest type'
							}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="7"
						/>
					</Grid>
 					*/}
					<Grid item xs={12} md={6}>
						{/* Loan Tenure */}
						<Dropdown
							items={MORTGAGE_LOAN_TENURE_OPTIONS}
							selected={ownershipTenure}
							onChange={setOwnershipTenure}
							variant="outlined"
							label="Desired loan term *"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="8"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Total annual income *'
									: 'Total annual income'
							}
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								tabIndex: '9',
								'data-test': 'annualIncome'
							}}
							type={NUMBER}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Desired Amount */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Desired Cash Out Refinance Amount *'
									: 'Desired Cash Out Refinance Amount'
							}
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								tabIndex: '10',
								'data-test': 'desiredLoanAmount'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employment Status */}
						<Dropdown
							items={EMPLOYMENT_STATUS_OPTIONS}
							selected={employmentStatus}
							onChange={setEmploymentStatus}
							variant="outlined"
							label="Employment status"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="12"
						/>
					</Grid>

					<GetFullAddressPartialForm
						isRequired
						addressLine1={addressLine1}
						addressLine1TabIndex="13"
						addressLine2={addressLine2}
						addressLine2TabIndex="14"
						city={city}
						cityTabIndex="15"
						state={state}
						stateTabIndex="16"
						zipCode={zipCode}
						zipCodeTabIndex="17"
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={16}
						/>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				<PrimaryButton
					disabled={!isFormReady}
					onClick={submitForm}
					// eslint-disable-next-line jsx-a11y/tabindex-no-positive
					tabIndex={17}
				>
					Submit
				</PrimaryButton>
			</Grid>
		</Box>
	);
}

export default CashOutRefiForm;
