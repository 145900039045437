import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// imported Icons
import AddIcon from '@mui/icons-material/Add';

// Our Components
import Loader from 'components/Loader';
import { TertiaryButton } from 'components/Button/Button';

// Our Routes
import { INVITE_ROUTE } from 'routes';

function FADashboardHeading({ isLoading }) {
	const navigate = useNavigate();

	return (
		<>
			<Helmet>
				<title>Financial advisor dashboard</title>
			</Helmet>

			{/* Child Grid container for Your highlights */}
			<Grid
				container
				sx={{
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					marginBottom: 4
				}}
			>
				<Typography variant="h1Gascogne" component="h1">
					Liability Dashboard
				</Typography>

				<TertiaryButton
					sx={{ marginRight: 4 }}
					startIcon={<AddIcon />}
					onClick={() => navigate(INVITE_ROUTE)}
				>
					Add Client
				</TertiaryButton>
			</Grid>

			{isLoading && (
				<Grid
					container
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'center'
					}}
				>
					<Loader size={70} />
				</Grid>
			)}
		</>
	);
}

export default FADashboardHeading;
