import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// Our Components
import InsightsCard from 'components/SoraWallet/Cards/insights/InsightsCard';
import InsightsModal from 'components/SoraWallet/Cards/insights/InsightsModal';
import SoraInsightsCarouselHeader from 'components/Carousel/SoraInsightCarouselHeader/SoraInsightsCarouselHeader';
import StandardCarousel from 'components/Carousel/StandardCarousel';

// Our Hooks
import useMortgageInsightsToChatGptResult from 'hooks/insights/useMortgageInsightsToChatGptResult';

function SoraInsightsMortgageCarousel({ isClient }) {
	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedInsight, setSelectedInsight] = useState(null);

	const openModal = () => setIsModalOpen(true);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedInsight(null);
	};

	const onClickCallBack = (currentlySelectedInsight) => {
		setSelectedInsight(currentlySelectedInsight);
		openModal();
	};

	const mortgageInsightsQueries = useMortgageInsightsToChatGptResult(
		true,
		isClient
	);

	const mortgageInsightCards = useMemo(
		() =>
			mortgageInsightsQueries.map((currentInsightQuery) => (
				<InsightsCard
					key={uuidv4()}
					sx={{ marginRight: 6, marginLeft: 6 }}
					isClient={isClient}
					currentInsightQuery={currentInsightQuery}
					onClick={onClickCallBack}
				/>
			)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mortgageInsightsQueries]
	);

	const currentMortgageHasNoInsights = mortgageInsightCards.length < 1;
	if (currentMortgageHasNoInsights) return <p> </p>;

	return (
		<>
			<SoraInsightsCarouselHeader />

			<StandardCarousel carouselItems={mortgageInsightCards} />

			{selectedInsight && (
				<InsightsModal
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					selectedInsight={selectedInsight}
				/>
			)}
		</>
	);
}

SoraInsightsMortgageCarousel.propTypes = {
	isClient: PropTypes.bool.isRequired
};

export default SoraInsightsMortgageCarousel;
