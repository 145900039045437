import { isValidElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Common Props
import CommonProps from 'shared/prop-types';

function PageHeading({ title, subtitleText, sx, subtitleSx }) {
	const isSubtitleElement = isValidElement(subtitleText);

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>

			<Box sx={{ height: 'fit-content' }}>
				<Typography
					variant="h1Gascogne"
					component="h1"
					sx={{
						marginBottom: 4,
						...sx
					}}
				>
					{title}
				</Typography>
				{isSubtitleElement ? (
					subtitleText
				) : (
					<Typography
						variant="body2"
						sx={{ marginBottom: 4, ...subtitleSx }}
					>
						{subtitleText}
					</Typography>
				)}
			</Box>
		</>
	);
}

PageHeading.propTypes = {
	title: PropTypes.string.isRequired,
	subtitleText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	sx: CommonProps.sx,
	subtitleSx: CommonProps.sx
};

PageHeading.defaultProps = {
	subtitleText: null,
	sx: {},
	subtitleSx: {}
};

export default PageHeading;
