import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

// Our components
import Modal from 'components/Modal/Modal';
import { SecondaryButton, TertiaryButton } from 'components/Button/Button';

// Our Routes
import { LOAN_PIPELINE_ROUTE } from 'routes';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

function LoanInquirySuccessModal({ isOpen, sx }) {
	const navigate = useNavigate();

	const [isModalOpen, setIsModalOpen] = useState(isOpen);

	const closeModal = useCallback(() => setIsModalOpen(false), []);

	return (
		<Modal
			title="Thank you for submitting a loan request"
			subtitle="Head to the tasks bar to finish adding required information, upload required documents and verify your request status."
			isOpen={isModalOpen}
			handleClose={closeModal}
			sx={sx}
		>
			<Grid container>
				<Grid item xs={12} sx={{ marginTop: 4 }}>
					<SecondaryButton onClick={() => window.location.reload()}>
						Submit New Loan Request
					</SecondaryButton>
					<TertiaryButton
						sx={{ marginLeft: 2 }}
						onClick={() => navigate(LOAN_PIPELINE_ROUTE)}
					>
						Review Loan Requests
					</TertiaryButton>
				</Grid>
			</Grid>
		</Modal>
	);
}

LoanInquirySuccessModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	sx: CommonPropTypes.sx
};

LoanInquirySuccessModal.defaultProps = {
	sx: {}
};

export default LoanInquirySuccessModal;
