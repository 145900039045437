import { useQueries, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

// Our Axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { chatGptInsightsUrl } from 'shared/api-urls';

// Our Query Keys
import {
	MORTGAGE_SPECIFIC_INSIGHTS,
	WALLET_OVERVIEW_DATA
} from 'shared/query-keys';

// Calculations
import { getFrontendMortgageReportInsights } from 'components/ExportableReport/getFrontEndInsights';

// Our Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

/*
 if insights_mode is 0 -> FE rules with chatGpt
 if insights_mode is 1 -> BE rules no chatGpt
 if insights_mode is 2 -> BE rules with chatGpt
*/

function useMortgageInsightsToChatGptResult(isReady, isClient) {
	const { clientId, tradelineId } = useParams();
	const queryClient = useQueryClient();

	const walletOverviewData = queryClient.getQueryData(
		isClient ? WALLET_OVERVIEW_DATA : [WALLET_OVERVIEW_DATA, clientId]
	);

	const currentWalletOverviewLiability =
		walletOverviewData.liability.liabilities.find(
			({ tradelineId: currentTradelineId }) =>
				currentTradelineId === +tradelineId
		);

	const DYNAMIC_CHATGPT_INSIGHTS_QUERY_KEY = clientId
		? [MORTGAGE_SPECIFIC_INSIGHTS, clientId, tradelineId]
		: [MORTGAGE_SPECIFIC_INSIGHTS, tradelineId];

	const safeInsights =
		getFrontendMortgageReportInsights(currentWalletOverviewLiability) ?? [];

	return useQueries(
		safeInsights.map((singleInsight, index) => ({
			queryKey: [...DYNAMIC_CHATGPT_INSIGHTS_QUERY_KEY, index],
			queryFn: async () => {
				const indexOfColon = singleInsight.indexOf(':');
				const insightType = singleInsight.slice(0, indexOfColon);
				const response = await axiosInstance.post(chatGptInsightsUrl, {
					snippet: singleInsight
				});

				const insight = response.data.data.rewritten;

				return { insightType, insightTextOutput: insight };
			},
			refetchOnWindowFocus: false,
			enabled: isReady,
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}))
	);
}

export default useMortgageInsightsToChatGptResult;
