import { Box, Grid, Typography } from '@mui/material';
import { useState, useMemo, useEffect, useContext } from 'react';

// Our Components
import DetailedAssetsTable from 'components/Analytics/Assumptions/DetailedAssetsTable';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader/index';
import JumboModal from 'components/Modal/JumboModal';
import RateInput from 'components/Input/RateInput';
import SimpleAssetsTable from 'components/Analytics/Assumptions/SimpleAssetsTable';
import TaxesAndDividendsSection from 'components/Analytics/Assumptions/TaxesAndDividendsSection';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetWalletOverviewAsAdvisor from 'hooks/useGetWalletOverviewAsAdvisor';
import useQueryGetAssumptions from 'hooks/analytics/assumptions/useQueryGetAssumptions';
import useMutateSaveAssumptions from 'hooks/analytics/assumptions/useMutateSaveAssumptions';

// Context
import AnalyticsContext from 'context/AnalyticsContext';

// Our Selectors
import selectLiabilitiesDataFromWalletOverview from 'selectors/selectLiabilitiesDataFromWalletOverview';

// Default State
import DEFAULT_STATE_DETAILED_ASSETS_TABLE from 'components/Analytics/Assumptions/defaultStateDetailedAssetsTable';

// Our Utils
import { dataIsValid, isSubmissionReady } from 'shared/utils';
import filterByTradelineType from 'shared/utils/filtering/filterByTradelineType';
import getTotalPropertyValue from 'shared/utils/calculations/getTotalPropertyValue';

// Our constants
import {
	APPLY_CAPITAL_GAINS_TAX_SIMPLE,
	APPLY_CAPITAL_GAINS_TAX_DETAILED,
	MORTGAGE
} from 'shared/constants';

// Local Constants
const DEFAULT_ASSET_ANNUAL_GROWTH_RATE = '6';
const DEFAULT_INCOME_TAX_RATE = '37';
const DEFAULT_CAPITAL_GAINS_RATE = '15';
const DEFAULT_AVERAGE_ANNUAL_DIVIDEND_RETURN_RATE = '1.28';
const DEFAULT_DIVIDEND_TAX_RATE = '15';
const DEFAULT_PROPERTY_INTEREST_TAX_DEDUCTIBLE = 'Yes';
const DEFAULT_APPLY_CAPITAL_GAINS_TAX = 'Apply to investment accounts';
const DEFAULT_RISK_FREE_FRATE_OF_RETURN = '5.25';

const PRE_POPULATE_FIELDS_TO_SKIP = new Set([
	'assumptionsAnalyticsId',
	'clientId',
	'defaultAssetCategory',
	'lastUpdated',
	'liabilities',
	'netIncome',
	'overflowAssetCategory'
]);

/**
 * This function returns the asset type based on its position in the table
 * @param {number} index
 * @returns {string}
 */
const getAssetType = (index) => {
	const isEven = index % 2 === 0;
	const isBrokerage = index < 2;
	const isNonBrokerage = index > 1 && index < 4;

	if (isBrokerage) return isEven ? 'Brokerage Stocks' : 'Brokerage Bonds';
	if (isNonBrokerage)
		return isEven ? 'Non-Brokerage Stocks' : 'Non-Brokerage Bonds';

	return 'Other';
};
/**
 * This function converts an asset object to a state object
 * @param {{type: string, presentValue: number, firstGrowth: number, firstYears: string}} asset
 * @returns {{assetCategory: string, assetType: string, presentValue: string, firstPeriodAnnualGrowth: string, durationOfGrowth: string, years: string}}
 */
const assetToState = (asset) => {
	const { type, presentValue, firstGrowth, firstYears } = asset;

	const isLimited = firstYears !== 'infinity';

	const stateConversion = {
		presentValue: `${presentValue}`,
		firstPeriodAnnualGrowth: `${firstGrowth}`,
		durationOfGrowth: isLimited ? 'Limited' : 'Unlimited',
		years: isLimited ? firstYears : ''
	};

	if (type === 'Other')
		return {
			assetCategory: type,
			assetType: type,
			...stateConversion
		};

	const isStocks = type.includes('Stocks');

	const separatedType = type.split(' ');

	const [assetCategory, assetType] = separatedType;

	if (isStocks) return { assetCategory, assetType, ...stateConversion };

	return { assetType, ...stateConversion };
};

function AssumptionsModal({ isModalOpen, setIsModalOpen, selectedClient }) {
	const saveAssumptionsMutation = useMutateSaveAssumptions();

	const {
		isSuccess: isGetAssumptionsSuccess,
		isLoading: isGetAssumptionsLoading,
		data
	} = useQueryGetAssumptions(selectedClient?.id);

	const { isSuccess: isWalletSuccess, data: walletData } =
		useGetWalletOverviewAsAdvisor(
			selectedClient?.id,
			true,
			selectLiabilitiesDataFromWalletOverview
		);

	const { isLoading } = saveAssumptionsMutation;

	const [assetsTableMode, setAssetsTableMode] = useState(
		'Simple Assets Table'
	);

	const { modalError, setModalError } = useContext(AnalyticsContext);

	// Investment Account
	const [investmentAccountPresentValue, setInvestmentAccountPresentValue] =
		useState('0');

	const [investmentAnnualGrowth, setInvestmentAnnualGrowth] = useState(
		DEFAULT_ASSET_ANNUAL_GROWTH_RATE
	);

	// Property
	const [propertyPresentValue, setPropertyPresentValue] = useState('0');
	const [propertyGrowthRate, setPropertyGrowthRate] = useState('3');

	// Taxes and dividends State
	const [incomeTaxRate, setIncomeTaxRate] = useState(DEFAULT_INCOME_TAX_RATE);
	const [capitalGainsTaxRate, setCapitalGainsTaxRate] = useState(
		DEFAULT_CAPITAL_GAINS_RATE
	);

	const [
		averageAnnualDividendReturnRate,
		setAverageAnnualDividendReturnRate
	] = useState(DEFAULT_AVERAGE_ANNUAL_DIVIDEND_RETURN_RATE);

	const [dividendTaxRate, setDividendTaxRate] = useState(
		DEFAULT_DIVIDEND_TAX_RATE
	);
	const [
		isPropertyInterestTaxDeductible,
		setIsPropertyInterestTaxDeductible
	] = useState(DEFAULT_PROPERTY_INTEREST_TAX_DEDUCTIBLE);

	const [applyCapitalGainsTax, setApplyCapitalGainsTax] = useState(
		DEFAULT_APPLY_CAPITAL_GAINS_TAX
	);

	const [riskFreeRateOfReturn, setRiskFreeRateOfReturn] = useState(
		DEFAULT_RISK_FREE_FRATE_OF_RETURN
	);

	const [rowsState, setRowsState] = useState(
		DEFAULT_STATE_DETAILED_ASSETS_TABLE
	);

	const handleModalClose = () => {
		setIsModalOpen(false);
		setModalError('');
	};

	const handleModeChange = (modeSelected) => {
		setAssetsTableMode(modeSelected);
		setApplyCapitalGainsTax(
			modeSelected === 'Simple Assets Table'
				? APPLY_CAPITAL_GAINS_TAX_SIMPLE[0]
				: APPLY_CAPITAL_GAINS_TAX_DETAILED[0]
		);
	};

	// Render Vars
	const isSimpleMode = assetsTableMode === 'Simple Assets Table';

	const clientSelectedName = useMemo(() => {
		if (!dataIsValid(selectedClient)) return '';

		// safe code
		const { name } = selectedClient;

		return name;
	}, [selectedClient]);

	const requiredSimpleFields = [
		investmentAccountPresentValue,
		investmentAnnualGrowth,
		propertyPresentValue,
		propertyGrowthRate
	];

	const requiredFields = [
		incomeTaxRate,
		capitalGainsTaxRate,
		averageAnnualDividendReturnRate,
		dividendTaxRate,
		riskFreeRateOfReturn
	];

	const rowsStateWithoutOther = rowsState.filter(
		(cRowState) => cRowState.assetCategory !== 'Other'
	);

	const isDetailedTableReady = rowsStateWithoutOther.every(
		({
			presentValue,
			firstPeriodAnnualGrowth,
			durationOfGrowth,
			years
		}) => {
			const fieldsBeforeDurationOfGrowth = [
				presentValue,
				firstPeriodAnnualGrowth
			];

			const allFields = [
				...fieldsBeforeDurationOfGrowth,
				durationOfGrowth,
				years
			];

			const isLimited = durationOfGrowth === 'Limited';

			return isLimited
				? isSubmissionReady(allFields)
				: isSubmissionReady(fieldsBeforeDurationOfGrowth);
		}
	);

	const isFormReady = isSimpleMode
		? isSubmissionReady([...requiredSimpleFields, ...requiredFields])
		: isDetailedTableReady && isSubmissionReady(requiredFields);

	const saveAssumptions = () => {
		const hasLiabilities =
			dataIsValid(data?.liabilities) && data?.liabilities.length > 0;

		const hasNetIncome =
			dataIsValid(data?.netIncome) && data?.netIncome > 0;

		const { id: clientId } = selectedClient;

		const shouldApplyCapitalGainsTax =
			applyCapitalGainsTax === 'Apply to investment accounts';

		const shouldPropertyInterestTaxDeductible =
			isPropertyInterestTaxDeductible === 'Yes';

		const liabilitiesPayload = hasLiabilities ? data.liabilities : [];
		const netIncome = hasNetIncome ? data.netIncome : 1;

		const detailedAssets = rowsState.map((cRowState, i) => {
			const type = getAssetType(i);

			const {
				presentValue,
				firstPeriodAnnualGrowth,
				durationOfGrowth,
				years
			} = cRowState;

			const yearsValue =
				durationOfGrowth === 'Limited' ? years : 'infinity';

			const currentRowPayload = {
				type,
				adjusted: false,
				presentValue: +presentValue,
				firstGrowth: +firstPeriodAnnualGrowth,
				firstYears: yearsValue,
				twoPeriods: false,
				secondGrowth: 0,
				secondYears: 0
			};

			return currentRowPayload;
		});

		const assumptionsPayload = {
			clientId,
			assumptionsMode: isSimpleMode ? 'Simple' : 'Detailed',
			assets: [
				{
					type: 'Investment accounts',
					adjusted: false,
					presentValue: investmentAccountPresentValue,
					firstGrowth: investmentAnnualGrowth,
					firstYears: 'infinity',
					twoPeriods: false,
					secondGrowth: 0,
					secondYears: 0
				},
				{
					type: 'Property',
					adjusted: false,
					presentValue: propertyPresentValue,
					firstGrowth: propertyGrowthRate,
					firstYears: 'infinity',
					twoPeriods: false,
					secondGrowth: 0,
					secondYears: 0
				},
				...detailedAssets
			],
			liabilities: liabilitiesPayload,
			netIncome,
			incomeTax: incomeTaxRate,
			capitalGainsTax: capitalGainsTaxRate,
			dividendReturnRate: averageAnnualDividendReturnRate,
			dividendTax: dividendTaxRate,
			applyCapitalGainsTax: shouldApplyCapitalGainsTax,
			propertyInterestTaxDeductible: shouldPropertyInterestTaxDeductible,
			discountRate: riskFreeRateOfReturn,
			endYear: data?.endYear ?? 20,
			defaultAssetCategory: 'Investment accounts',
			overflowAssetCategory: isSimpleMode
				? 'Investment accounts'
				: 'Brokerage'
		};

		saveAssumptionsMutation.mutate(assumptionsPayload, {
			onSuccess: () => {
				handleModalClose();
			}
		});
	};

	useEffect(() => {
		const clientHasAssumptions = dataIsValid(data);

		if ((isGetAssumptionsSuccess, clientHasAssumptions)) {
			const WANTED_KEY_SET_MAPPING = {
				assumptionsMode: setAssetsTableMode,
				capitalGainsTax: setCapitalGainsTaxRate,
				discountRate: setRiskFreeRateOfReturn,
				dividendReturnRate: setAverageAnnualDividendReturnRate,
				dividendTax: setDividendTaxRate,
				endYear: () => {}, // need to ask about this tomorrow
				incomeTax: setIncomeTaxRate,
				applyCapitalGainsTax: setApplyCapitalGainsTax,
				propertyInterestTaxDeductible:
					setIsPropertyInterestTaxDeductible
			};

			const assumptionFields = Object.keys(data);

			try {
				assumptionFields.forEach((assumptionField) => {
					if (PRE_POPULATE_FIELDS_TO_SKIP.has(assumptionField))
						return '';
					// Fields we are interested in

					const currentData = data[assumptionField];
					const setUpdater = WANTED_KEY_SET_MAPPING[assumptionField];

					if (assumptionField === 'assets') {
						const investmentAccount = currentData.find(
							(cData) => cData.type === 'Investment accounts'
						);

						const property = currentData.find(
							(cData) => cData.type === 'Property'
						);

						const brokerageAssets = currentData
							.filter(
								(cData) =>
									cData.type === 'Brokerage Stocks' ||
									cData.type === 'Brokerage Bonds'
							)
							.map(assetToState);

						const nonBrokerageAssets = currentData
							.filter(
								(cData) =>
									cData.type === 'Non-Brokerage Stocks' ||
									cData.type === 'Non-Brokerage Bonds'
							)
							.map(assetToState);

						const OtherAssets = currentData
							.filter((cData) => cData.type === 'Other')
							.map(assetToState);

						const hasDetailedAssets =
							brokerageAssets.length > 0 &&
							nonBrokerageAssets.length > 0 &&
							OtherAssets.length > 0;

						if (hasDetailedAssets) {
							setRowsState([
								...brokerageAssets,
								...nonBrokerageAssets,
								...OtherAssets
							]);
						}

						if (investmentAccount) {
							setInvestmentAccountPresentValue(
								`${investmentAccount.presentValue}`
							);
							setInvestmentAnnualGrowth(
								`${investmentAccount.firstGrowth}`
							);
						}

						if (property) {
							setPropertyPresentValue(`${property.presentValue}`);
							setPropertyGrowthRate(`${property.firstGrowth}`);
						} else if (isWalletSuccess) {
							// We can add the check for the mortgage here
							const filteredByMortgage = filterByTradelineType(
								walletData,
								MORTGAGE
							);

							const hasMortgages = filteredByMortgage.length > 0;

							if (hasMortgages) {
								const totalPropertyValue =
									getTotalPropertyValue(filteredByMortgage);

								setPropertyPresentValue(
									`${totalPropertyValue}`
								);
							}
						}

						return '';
					}

					if (assumptionField === 'propertyInterestTaxDeductible') {
						setUpdater(currentData ? 'Yes' : 'No');
						return '';
					}

					if (assumptionField === 'applyCapitalGainsTax') {
						const isSavedModeSimple =
							data?.assumptionsMode === 'Simple' ?? true;

						// Defaults to simple if its missing
						if (isSavedModeSimple) {
							setUpdater(
								currentData
									? 'Apply to investment accounts'
									: 'Do not apply to investment accounts'
							);
							return '';
						}

						setUpdater(
							currentData
								? 'Apply to non-brokerage'
								: 'Do not apply to non-brokerage'
						);
						return '';
					}

					if (assumptionField === 'assumptionsMode') {
						setUpdater(
							currentData === 'Simple'
								? 'Simple Assets Table'
								: 'Detailed Assets Table'
						);
						return '';
					}

					setUpdater(`${currentData}`);
					return '';
					// Safe
				});
			} catch (e) {
				console.error(e);
			}
		}

		if (isGetAssumptionsSuccess && !clientHasAssumptions) {
			if (isWalletSuccess) {
				// We can add the check for the mortgage here
				const filteredByMortgage = filterByTradelineType(
					walletData,
					MORTGAGE
				);

				const hasMortgages = filteredByMortgage.length > 0;

				if (hasMortgages) {
					const totalPropertyValue =
						getTotalPropertyValue(filteredByMortgage);

					setPropertyPresentValue(`${totalPropertyValue}`);
				}
			}
		}
	}, [
		data,
		isGetAssumptionsSuccess,
		isWalletSuccess,
		selectedClient?.id,
		isModalOpen
	]);

	if (isGetAssumptionsLoading)
		return <Loader loaderSX={{ display: 'none' }} />;

	return (
		<JumboModal
			maxWidth="lg"
			title={`${clientSelectedName} Assumptions`}
			subtitle="Enter base data to be used in all your optimization evaluations"
			isOpen={isModalOpen}
			handleClose={handleModalClose}
			errorText={modalError}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
				<Typography variant="subtitleGascongne">Assets</Typography>
				<Dropdown
					sx={{ width: 250, height: 40 }}
					selectSX={{
						minWidth: 250,
						height: 40
					}}
					menuProps={{
						PaperProps: {
							sx: { minWidth: 250 }
						}
					}}
					selected={assetsTableMode}
					onChange={handleModeChange}
					items={['Simple Assets Table']}
					size="small"
				/>
			</Box>

			{isSimpleMode && (
				<SimpleAssetsTable
					investmentAccountPresentValue={
						investmentAccountPresentValue
					}
					setInvestmentAccountPresentValue={
						setInvestmentAccountPresentValue
					}
					investmentAnnualGrowth={investmentAnnualGrowth}
					setInvestmentAnnualGrowth={setInvestmentAnnualGrowth}
					// Property Below
					propertyPresentValue={propertyPresentValue}
					setPropertyPresentValue={setPropertyPresentValue}
					propertyGrowthRate={propertyGrowthRate}
					setPropertyGrowthRate={setPropertyGrowthRate}
				/>
			)}

			{!isSimpleMode && (
				<DetailedAssetsTable
					rowsState={rowsState}
					setRowsState={setRowsState}
				/>
			)}

			<TaxesAndDividendsSection
				isSimpleMode={isSimpleMode}
				incomeTaxRate={incomeTaxRate}
				setIncomeTaxRate={setIncomeTaxRate}
				capitalGainsTaxRate={capitalGainsTaxRate}
				setCapitalGainsTaxRate={setCapitalGainsTaxRate}
				averageAnnualDividendReturnRate={
					averageAnnualDividendReturnRate
				}
				setAverageAnnualDividendReturnRate={
					setAverageAnnualDividendReturnRate
				}
				dividendTaxRate={dividendTaxRate}
				setDividendTaxRate={setDividendTaxRate}
				isPropertyInterestTaxDeductible={
					isPropertyInterestTaxDeductible
				}
				setIsPropertyInterestTaxDeductible={
					setIsPropertyInterestTaxDeductible
				}
				applyCapitalGainsTax={applyCapitalGainsTax}
				setApplyCapitalGainsTax={setApplyCapitalGainsTax}
			/>

			<Typography
				sx={{ marginTop: 3, marginBottom: 2, display: 'block' }}
				variant="subtitleGascongne"
			>
				Other
			</Typography>

			<Grid container sx={{ marginBottom: 3 }}>
				<Grid item xs={4}>
					<RateInput
						variant="body2"
						label="Risk-free rate of return?"
						subLabel=""
						rate={riskFreeRateOfReturn}
						setRate={setRiskFreeRateOfReturn}
					/>
				</Grid>
			</Grid>

			{isLoading && <Loader />}
			{!isLoading && (
				<TertiaryButton
					sx={{ paddingTop: 1, paddingBottom: 1 }}
					isDisabled={!isFormReady}
					onClick={saveAssumptions}
				>
					Submit
				</TertiaryButton>
			)}
		</JumboModal>
	);
}

export default AssumptionsModal;
