// Our Components
import PageHeading from 'components/PageHeading/PageHeading';
import OnboardingTasks from 'pages/Tasks/OnboardingTasks';

function AdvisorIntegrationsPage() {
	return (
		<>
			<PageHeading title="Integrations" />
			<OnboardingTasks />
		</>
	);
}

export default AdvisorIntegrationsPage;
