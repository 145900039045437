import { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

export default function YearMonthDatePicker({ sx, label }) {
	const [value, setValue] = useState(new Date());

	const handleChange = (event) => {
		setValue(event);
	};

	return (
		<>
			{label && (
				<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
					{label}
				</Typography>
			)}
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					views={['year', 'month']}
					minDate={new Date('1950-01-01')}
					maxDate={new Date('2050-01-01')}
					value={value}
					onChange={handleChange}
					renderInput={(params) => (
						<TextField {...params} helperText={null} />
					)}
					sx={{ ...sx, width: '100%' }}
					inputFormat="MM/yyyy"
				/>
			</LocalizationProvider>
		</>
	);
}

YearMonthDatePicker.defaultProps = {
	sx: {},
	label: 'Year and Month'
};

YearMonthDatePicker.propTypes = {
	sx: CommonPropTypes.sx,
	label: PropTypes.string
};
