// import { Box, Grid, Typography } from '@mui/material';
import { Box, Paper, Grid, Typography } from '@mui/material/index';

function WalletWellDoneCard() {
	const SoraMark =
		'https://prod-public-asset.s3.amazonaws.com/SoraLogos/Sora_Mark.png';
	return (
		// <Grid item sm={12} md={12} lg={4} xl={4}>
		// 	<CardItem withoutButton>
		// 		<Typography variant="h5">Sora&apos;s Working</Typography>
		// 		<Typography sx={{ marginTop: 2 }}>
		// 			Sora is actively scanning its lender partners and will alert
		// 			you if we find a savings opportunity.
		// 		</Typography>
		// 		<Box
		// 			sx={{
		// 				display: 'flex',
		// 				justifyContent: 'flex-end'
		// 			}}
		// 		>
		// 			<Box
		// 				component="img"
		// 				src={soraWings}
		// 				sx={{
		// 					width: 70,
		// 					height: 70,
		// 					float: 'right'
		// 				}}
		// 			/>
		// 		</Box>
		// 	</CardItem>
		// </Grid>
		// <Grid item sm={12} md={12} lg={4} xl={4}>
		// 	<Paper
		// 		elevation={5}
		// 		sx={{
		// 			padding: 3,
		// 			marginLeft: 7,
		// 			marginRight: 7,
		// 			marginTop: 2,
		// 			marginBottom: 2,
		// 			borderRadius: 6,
		// 			display: 'flex',
		// 			// flexDirection: 'column',
		// 			// justifyContent: 'space-evenly',
		// 			minHeight: 250,
		// 			background: 'linear-gradient(180deg, #FFF 0%, #F5F8FA 100%)'
		// 		}}
		// 	>
		// 		<Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
		// 			<Box
		// 				component="img"
		// 				src={SoraMark}
		// 				sx={{ height: '2.5rem' }}
		// 			/>
		// 			<Typography
		// 				variant="h3Gascogne"
		// 				sx={{ fontSize: '1.5rem' }}
		// 			>
		// 				Interest Rates
		// 			</Typography>
		// 			<Typography sx={{ marginTop: 2 }}>
		// 				Sora is actively scanning its lender partners and will
		// 				alert you if we find a savings opportunity.
		// 			</Typography>
		// 		</Box>
		// 	</Paper>
		// </Grid>
		<Grid
			container
			item
			xs={12}
			md={6}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-evenly'
			}}
		>
			<Paper
				elevation={5}
				sx={{
					padding: 3,
					paddingTop: 1,
					// marginLeft: 7,
					marginRight: 7,
					marginTop: 2,
					marginBottom: 2,
					borderRadius: 6,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					minHeight: 250,
					background: 'linear-gradient(180deg, #FFF 0%, #F5F8FA 100%)'
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
					<Box
						component="img"
						src={SoraMark}
						sx={{ height: '2.5rem' }}
					/>
					<Typography
						variant="h3Gascogne"
						sx={{ fontSize: '1.5rem' }}
					>
						Interest Rates
					</Typography>
				</Box>

				<Typography
					variant="body2"
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						wordWrap: 'break-word',
						WebkitLineClamp: 4
					}}
				>
					Sora is actively scanning its lender partners and will alert
					you if we find a savings opportunity.
				</Typography>
			</Paper>
		</Grid>
	);
}

export default WalletWellDoneCard;
