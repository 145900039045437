import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import { TertiaryButton } from 'components/Button/Button';

// Our Assets
import PersonalLoanImage from 'assets/images/liability/personal_loan.svg';
import DollarIcon from 'assets/icons/wallet_icons/NotesIcon.svg';

// OUr Utils
import { dollarFormatter } from 'shared/utils';

function UpsidePotentialCard({
	heading,
	tooltTipText,
	calculatedValue,
	withButton
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const formattedCalculatedValue = dollarFormatter.format(calculatedValue);

	return (
		<CardItem
			withoutButton
			toolTip={tooltTipText}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				paddingBottom: 1
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
				<Typography variant="subtitle1">{heading}</Typography>
				<Typography variant="h4" sx={{ color: primary.indigo }}>
					{formattedCalculatedValue}
				</Typography>
				{withButton && <TertiaryButton>Learn More</TertiaryButton>}
			</Box>
			<Box
				component="img"
				src={DollarIcon}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '5.5rem !important',
					height: ' 5.5rem !important',
					marginBottom: '0.375rem !important'
				}}
			/>
		</CardItem>
	);
}

UpsidePotentialCard.propTypes = {
	heading: PropTypes.string.isRequired,
	tooltTipText: PropTypes.string.isRequired,
	calculatedValue: PropTypes.number.isRequired,
	withButton: PropTypes.bool
};

UpsidePotentialCard.defaultProps = {
	withButton: false
};

export default UpsidePotentialCard;
