import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import TextInput from 'components/Input/TextInput';
import SoraTextField from 'components/Input/TextArea';
import YearDatePicker from 'components/DatePicker/YearDatePicker';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import { NUMBER, TEXT } from 'components/Input/Types';

// Our hooks 🪝
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import { isDate } from 'validator';
import { AUTO, NEW_LOAN_OFFER_TYPE } from 'shared/constants';

// Constants
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';
import USStateDropdown from 'components/Dropdown/USStateDropdown';

function NewAutomotiveLoanForm({
	additionalNotesValue,
	advisorEmail,
	advisorName,
	clientId,
	conditionValue,
	countyValue,
	creditScoreValue,
	dealershipSaleValue,
	downPaymentPercentValue,
	emailAddressValue,
	estimatedPurchaseAmount,
	firstName,
	lastName,
	loanAmountValue,
	loanRequestId,
	loanTenureValue,
	postSuccessEvent,
	stateValue,
	yearOfVehicleValue
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');
	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	const sendGenericEmail = useMutateSendGenericEmail();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [fullName, setFullName] = useState(fullNameValue || '');
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [state, setState] = useState(stateValue || '');
	const [county, setCounty] = useState(countyValue || '');
	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState(
		estimatedPurchaseAmount || ''
	);
	const [downPaymentPercent, setDownPaymentPercent] = useState(
		downPaymentPercentValue || ''
	);
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');
	const [loanTenure, setLoanTenure] = useState(loanTenureValue || '');
	const [condition, setCondition] = useState(conditionValue || 'New');
	const [yearOfVehicle, setYearOfVehicle] = useState(
		yearOfVehicleValue || new Date()
	);
	const [dealershipSale, setDealershipSale] = useState(
		dealershipSaleValue || ''
	);
	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [subject, setSubject] = useState('New Automotive Loan Request');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [state, estimatedPurchaseValue, loanAmount];

	const loanRequestDetailsRequiredFields = [
		state,
		estimatedPurchaseValue,
		downPaymentPercent,
		loanAmount,
		loanTenure,
		yearOfVehicle
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			),
		atLoanRequestDetailsUrl ? loanRequestDetailsRequiredFields : formData
	);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor has request a New Automotive Loan for one of their clients. 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for New Automotive Loan for the following CLIENT:

			Client's name: ${fullName}
			Client's Email address: ${emailAddress}
			Client's credit score: ${creditScore}
			Client's county: ${county}
			Client's state: ${state}
			Client's estimated purchase value: ${estimatedPurchaseValue}
			Client's down payment %: ${downPaymentPercent}
			Client's requested loan amount: ${loanAmount}
			Client's ideal loan tenure: ${loanTenure}
			Condition of vehicle intended to purchase: ${condition}
			Year of vehicle: ${yearOfVehicle}
			Is this a dealership vehicle sale?: ${dealershipSale}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			...formData,
			advisorName,
			advisorEmail,
			creditScore,
			county,
			state,
			estimatedPurchaseValue,
			downPaymentPercent,
			loanAmount,
			loanTenure,
			condition,
			dealershipSale,
			yearOfVehicle,
			additionalNotes
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setCondition,
						setCounty,
						setCreditScore,
						setDealershipSale,
						setDownPaymentPercent,
						setEmailAddress,
						setEstimatedPurchaseValue,
						setFullName,
						setLoanAmount,
						setLoanTenure,
						setState,
						setSubject,
						setYearOfVehicle
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			clientId,
			condition,
			county,
			creditScore,
			dealershipSale,
			downPaymentPercent,
			estimatedPurchaseValue,
			firstName,
			lastName,
			loanAmount,
			loanTenure,
			offerType: NEW_LOAN_OFFER_TYPE,
			state,
			tradelineType: AUTO,
			yearOfVehicle
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			clientId,
			condition,
			county,
			creditScore,
			dealershipSale,
			downPaymentPercent,
			estimatedPurchaseValue,
			firstName,
			lastName,
			loanAmount,
			loanTenure,
			offerType: NEW_LOAN_OFFER_TYPE,
			state,
			tradelineType: AUTO,
			yearOfVehicle
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setCondition,
						setCounty,
						setCreditScore,
						setDealershipSale,
						setDownPaymentPercent,
						setEmailAddress,
						setEstimatedPurchaseValue,
						setFullName,
						setLoanAmount,
						setLoanTenure,
						setState,
						setSubject,
						setYearOfVehicle
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const submitForm = (event) => {
		event.preventDefault();
		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	const isValidDate = useMemo(() => {
		const isStringDate = typeof yearOfVehicle === 'string';
		const isDateType = yearOfVehicle instanceof Date;
		if (isStringDate && yearOfVehicle !== '') {
			const isDateCheckWithConversion = isDate(new Date(yearOfVehicle));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(yearOfVehicle);
		}
		// if yearOfVehicle is null this returns false;
		return false;
	}, [yearOfVehicle]);

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						New Automotive Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<Grid container spacing={2}>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* State */}
						<USStateDropdown
							label="State of purchase *"
							state={state}
							setState={setState}
							inputProps={{
								'data-test': 'state'
							}}
							gridColumns={12}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="1"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* County */}
						<TextInput
							label="County"
							value={county}
							onChange={setCounty}
							type={TEXT}
							inputProps={{
								tabIndex: '2',
								'data-test': 'county'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Estimated Purchase Value */}
						<CurrencyTextInput
							label="Vehicle purchase value *"
							value={estimatedPurchaseValue}
							onChange={setEstimatedPurchaseValue}
							inputProps={{
								tabIndex: '3',
								'data-test': 'estimatedPurchaseValue'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Down payment % */}
						<TextInput
							type="percent"
							label={
								atLoanRequestDetailsUrl
									? 'Down payment % *'
									: 'Down payment %'
							}
							helperText="Down payment cannot be empty"
							onChange={setDownPaymentPercent}
							value={downPaymentPercent}
							inputProps={{
								tabIndex: '4',
								'data-test': 'downPaymentPercent'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Desired Loan Amount */}
						<CurrencyTextInput
							label="Desired loan amount *"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								tabIndex: '5',
								'data-test': 'loanAmount'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Loan Tenure */}
						<TextInput
							id="loanTenure"
							label={
								atLoanRequestDetailsUrl
									? 'Desired loan term *'
									: 'Desired loan term'
							}
							subLabel="Enter time in years"
							type={TEXT}
							name="Ideal loan length"
							onChange={setLoanTenure}
							value={loanTenure}
							inputProps={{
								tabIndex: '6',
								'data-test': 'loanTenure'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Dropdown
							items={['New', 'Used']}
							selected={condition}
							onChange={setCondition}
							variant="outlined"
							label="Vehicle condition"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="7"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<YearDatePicker
							id="yearOfVehicle"
							label={
								atLoanRequestDetailsUrl
									? 'Vehicle year *'
									: 'Vehicle year'
							}
							onChange={setYearOfVehicle}
							value={yearOfVehicle}
							error={yearOfVehicle === null || !isValidDate}
							errorText="Year of vehicle needs to be a valid date"
							inputProps={{
								tabIndex: '8',
								'data-test': 'yearOfVehicle'
							}}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="8"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Dropdown
							items={['Auto Dealership', 'Private Sale', 'Other']}
							selected={dealershipSale}
							onChange={setDealershipSale}
							variant="outlined"
							label="Point of purchase"
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex="9"
						/>
					</Grid>
					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={10}
						/>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				<RequiredFieldsTooltip>
					<span>
						<PrimaryButton
							disabled={!isFormReady}
							onClick={submitForm}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={13}
						>
							Submit
						</PrimaryButton>
					</span>
				</RequiredFieldsTooltip>
			</Grid>
		</Box>
	);
}

export default NewAutomotiveLoanForm;
