import { Box, Divider, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import {
	BarChart,
	Bar,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	LabelList
} from 'recharts';

// Our Components
import ResultsSwitch from 'components/Analytics/Results/ResultsSwitch';
import { SecondaryButton } from 'components/Button/Button';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function TooltipContent(props) {
	const { payload } = props;

	if (payload.length < 1) return <div />;

	const { lender } = payload[0].payload;

	if (lender === '') return <div />;

	return (
		<Paper elevation={8} sx={{ padding: 2, borderRadius: 6 }}>
			<Typography variant="body1">{lender}</Typography>
		</Paper>
	);
}

function getChartConfig(chartData) {
	const hasAllPositiveValues = chartData.every(
		(currentBar) => currentBar.value > 0
	);
	const hasAllNegativeValues = chartData.every(
		(currentBar) => currentBar.value < 0
	);
	const hasSomeNegativeValues = chartData.some(
		(currentBar) => currentBar.value < 0
	);

	const minValue = Math.min(
		...chartData.map((currentBar) => currentBar.value)
	);

	const maxValue = Math.max(
		...chartData.map((currentBar) => currentBar.value)
	);

	// default domain of the chart
	const domain = [0, 'auto'];

	const defaultConfig = {
		labelListPosition: 'right',
		domain
	};

	if (hasAllPositiveValues)
		return {
			...defaultConfig,
			domain: [0, `dataMax + ${maxValue * 0.08}`]
		};

	if (hasAllNegativeValues)
		return {
			...defaultConfig,
			domain: [`dataMin - ${-minValue * 0.08}`, 0]
		};

	if (hasSomeNegativeValues) {
		return {
			...defaultConfig,
			labelListPosition: 'left'
		};
	}

	return defaultConfig;
}

function DynamicBarChart({ data }) {
	const { labelListPosition, domain } = getChartConfig(data);

	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				barSize={40}
				barGap={20}
				layout="vertical"
				width="100%"
				height="100%"
				data={data}
			>
				<CartesianGrid horizontal={false} />
				<YAxis
					type="category"
					dataKey="name"
					width={140}
					tickLine={false}
					tick={{
						fill: 'black'
					}}
					tickMargin={10}
					stroke="#e6e6e6"
				/>
				<XAxis
					domain={domain}
					type="number"
					tickFormatter={(xValue) => {
						const formattedValue = dollarFormatter.format(xValue);
						return formattedValue;
					}}
					tick={{
						fill: 'black'
					}}
					tickLine={false}
					tickMargin={10}
					stroke="#e6e6e6"
				/>

				<Tooltip
					content={TooltipContent}
					cursor={{ fill: 'transparent' }}
				/>

				<Bar dataKey="value" fill={data.fill}>
					<LabelList
						dataKey="value"
						position={labelListPosition}
						style={{
							fill: '#585858'
						}}
						formatter={(v) => {
							const formattedValue = dollarFormatter.format(v);
							return formattedValue;
						}}
					/>
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}

const strippedAndLowercased = (c) =>
	c.replace(/\s/g, '').replace(/\d+$/, '').toLowerCase();

// These Results refer to analytics results
function Results({ data, reset }) {
	const SoraTheme = useTheme();

	const { resultsChart } = SoraTheme.palette;

	// false -> present value, true -
	const [isFutureMode, setIsFutureMode] = useState(true);

	const { present, future } = data;

	const currentChartData = isFutureMode ? future : present;

	const { values, labels, caption, recommendation } = currentChartData;

	const currentBarchartData = values.map((value, index) => {
		const currentBar = labels[index];
		const { lender, type } = currentBar;

		const getFillKey =
			type === 'Non-Brokerage'
				? 'nonBrokerage'
				: strippedAndLowercased(type);

		const fill = resultsChart[getFillKey] ?? resultsChart.other;

		return {
			name: type,
			lender,
			value,
			fill
		};
	});

	const subtitleText = isFutureMode
		? 'Total Impact on Future Net Worth ($K)'
		: 'Total Impact on Present Value of Future Cash Flows ($K)';

	return (
		<>
			<Divider sx={{ marginTop: 4, backgroundColor: 'black' }} />

			<Typography
				variant="h1Gascogne"
				component="h1"
				sx={{ marginTop: 6 }}
			>
				Your Results
			</Typography>

			<Box
				sx={{
					width: '100%',
					marginTop: 4,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Typography
					variant="subtitleGascongne"
					component="h6"
					sx={{ fontSize: 24 }}
				>
					{subtitleText}
				</Typography>

				<Box
					sx={{
						border: '1px solid black',
						padding: 2,
						borderRadius: 3,
						background: 'rgba(38, 131, 194, 0.10)'
					}}
				>
					<Typography variant="body1">
						<strong>Recommendation:</strong> {recommendation}
					</Typography>
				</Box>
			</Box>

			<ResultsSwitch
				checked={isFutureMode}
				setChecked={setIsFutureMode}
			/>

			<Box sx={{ width: '100%', height: 600, marginTop: 4 }}>
				<DynamicBarChart data={currentBarchartData} />
			</Box>

			<Typography
				variant="caption"
				sx={{ display: 'inline-block', marginTop: 4, lineHeight: 2 }}
			>
				{caption}
			</Typography>

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					marginTop: 4
				}}
			>
				<SecondaryButton onClick={() => reset()}>
					Update data
				</SecondaryButton>
			</Box>
		</>
	);
}

export default Results;
