import PropTypes from 'prop-types';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';

// Utilities
import {
	dollarFormatter,
	extractSoraScore,
	roundToDecimal
} from 'shared/utils';
import convertDateToAcceptedFormat from 'shared/utils/formatting/convertDateToDatePickerFormat';
import splitByComma from 'shared/utils/formatting/splitListByCommas';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

function DetailedListCard({ cardHeading, currentLoan, soraScore, open }) {
	const [expanded, setExpanded] = useState(open);

	const handleChange = () => {
		setExpanded(!expanded);
	};

	const BalanceOutstanding = currentLoan?.totalOutstandingBalance ?? 0;
	const MonthlyPayment = currentLoan?.monthlyMortgagePayment ?? 0;
	const InterestRate = currentLoan?.interestRate ?? 0;
	const SoraScore = soraScore ?? 'N/A';
	const RemainingMonths = currentLoan?.remainingMonth ?? 0;
	const YearsRemaining =
		RemainingMonths < 12 ? '< 1' : roundToDecimal(RemainingMonths / 12, 1);
	const TotalCostOfDebt = currentLoan?.costOfDebt ?? 0;
	const OriginalMortgageBalance = currentLoan?.originalMortgageBalance ?? 0;
	const OriginalMortgageDate = currentLoan?.originalMortgageDate ?? 'N/A';

	function createData(label, value) {
		return { label, value };
	}

	const CurrentLiabilitiesDataCardRows = [
		createData(
			'Balance Outstanding',
			dollarFormatter.format(BalanceOutstanding)
		),
		createData('Monthly Payment', dollarFormatter.format(MonthlyPayment)),
		createData('Interest Rate', `${roundToDecimal(InterestRate, 2)}%`),
		createData('Sora Score', extractSoraScore(SoraScore)),
		createData('Years Remaining', YearsRemaining),
		createData(
			'Total Cost of Debt',
			dollarFormatter.format(TotalCostOfDebt)
		),
		createData(
			'Original Mortgage Balance',
			dollarFormatter.format(OriginalMortgageBalance)
		),
		createData('Original Mortgage Date', OriginalMortgageDate)
	];

	const AddressLine1 = sentenceCapitalize(
		currentLoan?.home?.addressLine1 ?? 'Data Not Found'
	);
	const City = sentenceCapitalize(
		currentLoan?.home?.city ?? 'Data Not Found'
	);
	const State = currentLoan?.home?.state ?? 'Data Not Found';
	const Zip = currentLoan?.home?.zipcode ?? 'Data Not Found';

	const Address = `${AddressLine1} ${City} ${State}, ${Zip}`;
	const HomeValue = currentLoan?.home?.estimatedValue ?? 0;
	const ConfidenceInValue = `${
		currentLoan?.home?.confidenceScore ?? 'Data Not Found'
	}`;
	const PropertyType = currentLoan?.home?.propertyType ?? 'Data Not Found';

	const PropertyTypeFormatted =
		PropertyType === 'SFR' ? 'Single Family Residence' : PropertyType;
	const YearBuilt = currentLoan?.home?.builtYear ?? 'Data Not Found';
	const RawTotalSquareFootage =
		currentLoan?.home?.toalSqft ?? 'Data Not Found';
	const TotalSquareFootage = `${
		RawTotalSquareFootage === 'Data Not Found'
			? RawTotalSquareFootage
			: `${RawTotalSquareFootage} sq. ft.`
	}`;
	const NumBedrooms = currentLoan?.home?.bedroomCount ?? 'Data Not Found';
	const NumBathrooms = currentLoan?.home?.bathCount ?? 'Data Not Found';
	const OwnersNames = splitByComma(
		currentLoan?.home?.ownerName ?? 'Data not found'
	);

	const OwnersNamesFormatted = sentenceCapitalize(
		OwnersNames?.replaceAll(',', '')?.trim() ?? 'Data Not Found'
	);

	const SellersName = sentenceCapitalize(
		currentLoan?.home?.sellerName ?? 'Data Not Found'
	);
	const RawSalesDate = currentLoan?.home?.saleTxDate ?? 'Data Not Found';
	const SaleDate =
		RawSalesDate === 'Data Not Found'
			? RawSalesDate
			: convertDateToAcceptedFormat(RawSalesDate);
	const SaleAmount = currentLoan?.home?.saleAmount ?? 0;
	const AssessedValue = currentLoan?.home?.assedValueUsd ?? 0;

	const PropertyDataCardRows = [
		createData('Address', Address),
		createData('Home Value', dollarFormatter.format(HomeValue)),
		createData('Confidence in Value', ConfidenceInValue),
		createData('Property Type', PropertyTypeFormatted),
		createData('Year Built', YearBuilt),
		createData('Total Square Footage', TotalSquareFootage),
		createData('Bedrooms', NumBedrooms),
		createData('Bathrooms', NumBathrooms),
		createData('Listed Owners Names', OwnersNamesFormatted),
		createData('Seller', SellersName),
		createData('Sales Transaction Date', SaleDate),
		createData('Sales Amount', dollarFormatter.format(SaleAmount)),
		createData('Assessed Value', dollarFormatter.format(AssessedValue))
	];

	return (
		<Grid item sm={12} md={12} lg={12} xl={12}>
			<CardItem withoutButton>
				<Accordion
					expanded={expanded}
					elevation={0}
					onChange={handleChange}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel-content"
						id="panel-header"
						sx={{ padding: 0 }}
					>
						<Typography variant="h5">{cardHeading}</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: 0 }}>
						<TableContainer component={Box}>
							<Table aria-label="Liability Data table">
								<TableBody>
									{/* Check to see which card is being served and conditionally render to correct rows */}
									{(cardHeading === 'Current Liabilities Data'
										? CurrentLiabilitiesDataCardRows
										: PropertyDataCardRows
									).map((row) => (
										<TableRow
											key={row.label}
											sx={{
												'&:last-child td, &:last-child th':
													{ border: 0 }
											}}
										>
											<TableCell
												component="th"
												scope="row"
												sx={{ fontWeight: 'bold' }}
											>
												{row.label}
											</TableCell>
											<TableCell>{row.value}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</CardItem>
		</Grid>
	);
}

DetailedListCard.propTypes = {
	cardHeading: PropTypes.string.isRequired,
	soraScore: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	currentLoan: PropTypes.object,
	open: PropTypes.bool
};

DetailedListCard.defaultProps = {
	soraScore: 'SORA_GRADE_A',
	currentLoan: {},
	open: false
};

export default DetailedListCard;
