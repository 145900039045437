import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Our Component
import CardItem from 'components/SoraWallet/Cards/CardItem';
import SoraToolTip from 'components/Tooltip';

// Wallet Json Data
import WalletToolTips from 'components/SoraWallet/WalletToolTips.json';

// Our Utility
import { dollarFormatter, getFormattedLiabilityRate } from 'shared/utils';

// Our Prop Types
import {
	currentLoanPropShape,
	homePropShape
} from 'components/SoraWallet/WalletPropTypes';

// Our Assets
import WalletGraph from 'assets/icons/wallet_icons/WalletGraph.svg';

function BalanceAndMonthyAmountCard({ currentLoanDetails }) {
	const cardImage = WalletGraph;
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const { monthlyPayment, totalOutstandingBalance: outstandingBalance } =
		currentLoanDetails;

	const formattedOutstandingBalance =
		dollarFormatter.format(outstandingBalance);
	const formattedMonthlyAmount = dollarFormatter.format(monthlyPayment);
	return (
		<CardItem
			withoutButton
			buttonSx={{ alignSelf: 'center' }}
			sx={{
				background: 'linear-gradient(180deg, #295983 0%, #91B0CB 100%)'
			}}
		>
			<Box>
				<Box sx={{ display: 'flex' }}>
					<Typography
						variant="subtitle1"
						sx={{ marginBottom: 2, color: primary.white }}
					>
						Balance Outstanding
					</Typography>
				</Box>

				<Typography
					variant="h4"
					sx={{ color: primary.white, marginBottom: 4 }}
				>
					{formattedOutstandingBalance}
				</Typography>
			</Box>

			<Box sx={{ display: 'flex' }}>
				<Typography variant="subtitle1" sx={{ color: primary.white }}>
					Monthly Payment
				</Typography>
			</Box>

			<Typography variant="h4" sx={{ color: primary.white }}>
				{formattedMonthlyAmount}
			</Typography>
			<Box
				component="img"
				src={cardImage}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '5.5rem !important',
					height: ' 5.5rem !important',
					marginBottom: '0.375rem !important'
				}}
			/>
		</CardItem>
	);
}

export default BalanceAndMonthyAmountCard;
