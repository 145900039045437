import { Box, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';
import { useTheme } from '@emotion/react';

// Our components
import ResponsiveTable from 'components/Table/ResponsiveTable';
import SoraToolTip from 'components/Tooltip/index';

// Our utils
import createFormattedRowData from 'shared/utils/analytics/createFormattedRowData';
import { dollarFormatter } from 'shared/utils';

// Local Constants
const LINE_STROKE_WIDTH = 5;

function UpsidePotentialTab({ analyticsUpsidePotentialData }) {
	const SoraTheme = useTheme();

	const { analytics } = SoraTheme.palette;

	const { compoundings, currentLoan, bestOffer } =
		analyticsUpsidePotentialData;

	const {
		monthlyMortgagePayment: currentLoanMonthlyMortgagePayment,
		monthlyPaymentSavings,
		remainingMonth,
		upsidePotential
	} = currentLoan;

	const { monthlyMortgagePayment: bestOfferMonthlyMortgagePayment } =
		bestOffer;

	const savingsInvestmentPotentialTableRows = [
		createFormattedRowData(
			'Current Monthly Payment',
			currentLoanMonthlyMortgagePayment,
			bestOfferMonthlyMortgagePayment
		),
		createFormattedRowData(
			'Monthly Payment Savings',
			monthlyPaymentSavings,
			''
		),
		['Current Loan Term', remainingMonth, ''],
		createFormattedRowData('Upside Potential', upsidePotential, '')
	];

	const formattedGraphData = useMemo(() => {
		if (analyticsUpsidePotentialData)
			return compoundings.map(
				({
					year,
					contribution,
					upsidePotential: compoundingUpsidePotential
				}) => ({
					year,
					contribution,
					FV: compoundingUpsidePotential
				})
			);

		return [];
	}, [analyticsUpsidePotentialData]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
				<Typography
					variant="h6"
					sx={{
						color: SoraTheme.palette.primary.soraBlue,
						marginBottom: 6
					}}
				>
					Savings Investment Potential
				</Typography>
				<SoraToolTip text="Upside potential represents the asset growth opportunity achieved refinancing your mortgage to the new loan offer and reinvesting monthly savings at the provided rate of return." />
			</Box>

			<Grid container spacing={2}>
				<Grid
					item
					xs={12}
					xl={6}
					sx={{
						marginBottom: 4,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<ResponsiveContainer height={500} width="90%">
						<LineChart data={formattedGraphData ?? []}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="year"
								label={{
									value: 'Year',
									position: 'left',
									offset: 10
								}}
							/>

							<YAxis
								width={120}
								tickFormatter={(yAxisTickValue) => {
									const formattedValue =
										dollarFormatter.format(yAxisTickValue);
									return formattedValue;
								}}
							/>
							<Tooltip
								formatter={(value) =>
									dollarFormatter.format(value)
								}
							/>
							<Legend />
							<Line
								strokeWidth={LINE_STROKE_WIDTH}
								dot={false}
								type="monotoneY"
								dataKey="FV"
								stroke={analytics.upsidePotentialFV}
							/>
							<Line
								strokeWidth={LINE_STROKE_WIDTH}
								dot={false}
								type="monotone"
								dataKey="contribution"
								name="Contribution"
								stroke={analytics.upsidePotentialContribution}
							/>
						</LineChart>
					</ResponsiveContainer>
				</Grid>

				<Grid item xs={12} xl={6} sx={{ marginBottom: 4 }}>
					<ResponsiveTable
						makeLastBold
						columns={['', 'Current Loan', 'Best Offer']}
						rows={savingsInvestmentPotentialTableRows}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default UpsidePotentialTab;
