import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import SoraTextField from 'components/Input/TextArea';
import { NUMBER, TEXT } from 'components/Input/Types';

// Our hooks 🪝
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';

// Constants
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';
import { FARM, NEW_LOAN_OFFER_TYPE } from 'shared/constants';

export function FarmLoanForm({
	acresValue,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	advisorEmail,
	advisorName,
	cityValue,
	clientId,
	creditScoreValue,
	disableAutoComplete,
	emailAddressValue,
	firstName,
	landUseValue,
	lastName,
	loanAmountValue,
	loanRequestId,
	postSuccessEvent,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue
}) {
	const queryClient = useQueryClient();
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	const sendGenericEmail = useMutateSendGenericEmail();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [fullName, setFullName] = useState(fullNameValue || '');
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');
	const [acres, setAcres] = useState(acresValue || '');
	const [landUse, setLandUse] = useState(landUseValue || '');

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [subject, setSubject] = useState('Farm Loan Request');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [fullName];
	const isFormReady = useMemo(() => isSubmissionReady(formData), formData);

	useEffect(
		() =>
			setMessage(`<pre>
			The advisor ${advisorName} has request a Farm Loan for one of their clients. 
			
			Advisor making request:
			Advisor's name: ${advisorName}
			Advisor's email: ${advisorEmail}
			
			They made a request for Farm Loan for the following CLIENT:

			Client's name: ${fullName}
			Client's Email address: ${emailAddress}
			Client's credit score: ${creditScore}
			Requested loan amount: ${loanAmount}
			Address of property: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
			# of acres: ${acres}
			Land use: ${landUse}

			Additional Notes:

			${additionalNotes}</pre>
		`),
		[
			...formData,
			emailAddress,
			creditScore,
			loanAmount,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			acres,
			landUse,
			additionalNotes
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setCreditScore,
						setLoanAmount,
						setAcres,
						setLandUse,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setEmailAddress,
						setMessage,
						setAdditionalNotes,
						setSubject
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			acres,
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			emailAddress,
			firstName,
			landUse,
			lastName,
			loanAmount,
			offerType: NEW_LOAN_OFFER_TYPE,
			state,
			tradelineType: FARM,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			acres,
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			emailAddress,
			firstName,
			landUse,
			lastName,
			loanAmount,
			state,
			tradelineType: FARM,
			offerType: NEW_LOAN_OFFER_TYPE,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setCreditScore,
						setLoanAmount,
						setAcres,
						setLandUse,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setEmailAddress,
						setMessage,
						setAdditionalNotes,
						setSubject
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();
		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Farm Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						{sendFormDataError && <Alert variant="error" />}

						{/* Modal shown on successful submission of  */}
						<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

						{/* Full Name */}
						<Input
							id="fullName"
							label="Full Name"
							type={TEXT}
							name="Full name"
							onChange={setFullName}
							value={fullName}
							endAdornment="hidden"
							sx={{
								width: '100%'
							}}
							inputProps={{
								tabIndex: '1',
								'data-test': 'fullName'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Credit Score */}
						<Input
							label="Credit score"
							value={creditScore}
							onChange={setCreditScore}
							type={NUMBER}
							inputProps={{
								tabIndex: '2',
								'data-test': 'creditScore'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Desired Loan Amount */}
						<CurrencyTextInput
							label="Desired loan amount"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								tabIndex: '3',
								'data-test': 'loanAmount'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Acres */}
						<Input
							label="Number of acres"
							subLabel="Approximate number of acres of property"
							value={acres}
							onChange={setAcres}
							type={NUMBER}
							inputProps={{
								tabIndex: '4',
								'data-test': 'numAcres'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Land use input */}
						<Input
							label="What will the land be used for?"
							value={landUse}
							onChange={setLandUse}
							type={TEXT}
							inputProps={{
								tabIndex: '5',
								'data-test': 'landUse'
							}}
						/>
					</Grid>

					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine1TabIndex="6"
						addressLine2={addressLine2}
						addressLine2TabIndex="7"
						city={city}
						cityTabIndex="8"
						state={state}
						stateTabIndex="9"
						zipCode={zipCode}
						zipCodeTabIndex="10"
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>
					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
							// eslint-disable-next-line jsx-a11y/tabindex-no-positive
							tabIndex={11}
						/>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				<PrimaryButton
					disabled={!isFormReady}
					onClick={submitForm}
					// eslint-disable-next-line jsx-a11y/tabindex-no-positive
					tabIndex={12}
				>
					Submit
				</PrimaryButton>
			</Grid>
		</Box>
	);
}

export default FarmLoanForm;
